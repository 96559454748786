export const routes = {
  introPage: '/',
  feedbackFormPage1: (linkCode: string) => `/lecture/feedback-form-page-1/${linkCode}`,
  feedbackFormPage2: (linkCode: string) => `/lecture/feedback-form-page-2/${linkCode}`,
  feedbackFormPage2b: (linkCode: string) => `/lecture/feedback-form-page-2b/${linkCode}`,
  feedbackFormPage3: (linkCode: string) => `/lecture/feedback-form-page-3/${linkCode}`,
  feedbackFormPage4: (linkCode: string) => `/lecture/feedback-form-page-4/${linkCode}`,
  feedbackFormV2Page1: (linkCode: string) => `/lecture/feedback-form-v2-page-1/${linkCode}`,
  feedbackFormV2Page2: (linkCode: string) => `/lecture/feedback-form-v2-page-2/${linkCode}`,
  feedbackFormV2Page3: (linkCode: string) => `/lecture/feedback-form-v2-page-3/${linkCode}`,
  feedbackStatistics: '/user/feedback-statistics',
};
