import * as React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer } from '@mui/material';
import { StyledTableRow } from '../../components/Table';

// const unfullfiledNeedsList = [
//   //   {
//   //   title: 'Společenství',
//   //   icon: <SpaIcon style={{ transform: 'scale(4)' }} />,
//   //   count: 6,
//   // },
//   {
//     title: 'Bezpečí a jistota',
//     icon: <HomeIcon style={{ transform: 'scale(4)' }} />,
//     count: 8,
//   },
//   {
//     title: 'Fyziologické potřeby',
//     icon: <Flatware style={{ transform: 'scale(4)' }} />,
//     count: 5,
//   },
// ];

const textRows = [
  // {
  //   message: 'Víc práce ve skupinách',
  // },
  {
    message: 'Vědět dopředu, kdy bude test',
  },
  {
    message: 'Pohyb při hodině, po obědě jsem unavený',
  },
];

export const StatisticsQuadrantDetailPage = () => {
  return (
    <>
      <div style={{ margin: 'auto', width: 2400 }}>
        <Paper elevation={3}>
          <Box p={3}>
            {/*<Typography variant="h5" component="div" sx={{flexGrow: 1}} mb={3} style={{ fontWeight: 800, fontSize: 80 }}>*/}
            {/*  <div style={{display: 'flex', alignItems: 'center'}}>*/}
            {/*    <span style={{ marginRight: 12 }}>Zvolené kvadranty</span>*/}
            {/*    <div style={{*/}
            {/*      width: 64,*/}
            {/*      height: 64,*/}
            {/*      background: 'rgba(255,141,141,1)',*/}
            {/*      borderRadius: 50,*/}
            {/*      marginRight: 12,*/}
            {/*      display: 'flex',*/}
            {/*      alignItems: 'center',*/}
            {/*      justifyContent: 'center'*/}
            {/*    }}>*/}
            {/*      <MoodBad fontSize={'large'} style={{ transform: 'scale(1.25)' }} />*/}
            {/*    </div>*/}
            {/*    <div style={{*/}
            {/*      width: 64,*/}
            {/*      height: 64,*/}
            {/*      background: 'rgba(110,193,253,1)',*/}
            {/*      borderRadius: 50,*/}
            {/*      marginRight: 12,*/}
            {/*      display: 'flex',*/}
            {/*      alignItems: 'center',*/}
            {/*      justifyContent: 'center'*/}
            {/*    }}>*/}
            {/*      <SentimentVeryDissatisfied fontSize={'large'} style={{ transform: 'scale(1.25)' }} />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Typography>*/}

            {/*<Typography variant="h6" component="div" sx={{flexGrow: 1}} mb={4} mt={5} style={{ fontSize: 64 }}>*/}
            {/*  <b>Přehled nenaplněných potřeb</b>*/}
            {/*</Typography>*/}

            {/*<Box mb={4}>*/}
            {/*  <TableContainer component={Paper}>*/}
            {/*    <Table sx={{minWidth: 650}} aria-label="simple table">*/}
            {/*      /!*<TableHead>*!/*/}
            {/*      /!*  <StyledTableRow>*!/*/}
            {/*      /!*    <StyledTableCell style={{ fontWeight: 800, fontSize: 40, paddingTop: 30, paddingBottom: 30 }}>Potřeba</StyledTableCell>*!/*/}
            {/*      /!*    <StyledTableCell align="right" style={{ fontWeight: 800, fontSize: 40, paddingTop: 30, paddingBottom: 30 }}>Počet záznamů</StyledTableCell>*!/*/}
            {/*      /!*  </StyledTableRow>*!/*/}
            {/*      /!*</TableHead>*!/*/}
            {/*      <TableBody>*/}
            {/*        {unfullfiledNeedsList.map((need, i) => (*/}
            {/*          <StyledTableRow*/}
            {/*            hover*/}
            {/*            key={i}*/}
            {/*            sx={{'&:last-child td, &:last-child th': {border: 0}}}*/}
            {/*          >*/}
            {/*            <TableCell component="th" scope="row" style={{ paddingTop: 30, paddingBottom: 30 }}>*/}
            {/*              <div style={{ display: 'flex', alignItems: 'center', fontSize: 36, fontWeight: 800 }}>*/}
            {/*                <Box mr={1} ml={6} style={{ marginRight: 64 }}>{need.icon}</Box> <Typography style={{ lineHeight: 1, fontSize: 120 }}>{need.title}</Typography>*/}
            {/*              </div>*/}
            {/*            </TableCell>*/}
            {/*            <TableCell component="th" scope="row" align="right" style={{ fontSize: 120,  paddingTop: 30, paddingBottom: 30  }}>*/}
            {/*              {need.count}x*/}
            {/*            </TableCell>*/}
            {/*          </StyledTableRow>*/}
            {/*        ))}*/}
            {/*      </TableBody>*/}
            {/*    </Table>*/}
            {/*  </TableContainer>*/}
            {/*</Box>*/}

            {/*<Typography variant="h6" component="div" sx={{flexGrow: 1}} mb={4} style={{ fontSize: 64 }}>*/}
            {/*  <b>Feedback spojený s nenaplněnými potřebami</b>*/}
            {/*</Typography>*/}

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {/*<TableHead>*/}
                {/*  <StyledTableRow>*/}
                {/*    <StyledTableCell  style={{ fontWeight: 800, fontSize: 40, paddingTop: 30, paddingBottom: 30 }}><b>Zpráva</b></StyledTableCell>*/}
                {/*  </StyledTableRow>*/}
                {/*</TableHead>*/}
                <TableBody>
                  {textRows.map((textRow, i) => (
                    <StyledTableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontSize: 120, paddingTop: 60, paddingBottom: 60, lineHeight: 1 }}
                      >
                        {textRow.message}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </div>
    </>
  );
};
