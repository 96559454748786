import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const createOptions = (min: number, max: number, indexAxis: string) => ({
  scales: {
    y: {
      ticks: {
        display: true,
        min,
        max,
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  indexAxis,
  // plugins: {
  //   legend: {
  //     position: 'right' as const,
  //   },
  // },
});

interface Props {
  data: any;
  options: { min: number; max: number };
  indexAxis?: 'x' | 'y';
}

export const BarChart = ({ data, options, indexAxis = 'x' }: Props) => {
  // @ts-ignore
  return <Bar options={createOptions(options.min, options.max, indexAxis)} data={data} />;
};
