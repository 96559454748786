import * as React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { BubbleChart } from '../../components/BubbleChart';
import { BarChart } from '../../components/BarChart';
import { barChartMixedNeedsData } from '../../utils/fixtures';
import { faker } from '@faker-js/faker';
import { TabPanel } from '../../components/TabPanel';
import { IconButtonBox } from '../../components/IconButtonBox';
import { SelectedEmotions } from '../../components/SelectedEmotions';
import HomeIcon from '@mui/icons-material/Home';
import HandshakeIcon from '@mui/icons-material/Handshake';

// const emotions = [{
//   title: 'Spokojenost',
//   color: '#F2E94E',
// }, {
//   title: 'Smysluplnost',
//   color: '#41658A',
// }, {
//   title: 'Vděčnost',
//   color: '#D0E562',
// }, {
//   title: 'Radost',
//   color: '#BF3100',
// }];

const emotions = ['61', '62', '25'];

// const emotions = [{
//   id: '61',
//   title: 'Spokojenost',
//   type: 'notIntensePleasant',
// },
//   {
//     id: '62',
//     title: 'Pohoda',
//     type: 'notIntensePleasant',
//   },    {
//     id: '25',
//     title: 'Odhodlání',
//     type: 'intensePleasant',
//   },];

const needs = [
  {
    id: '1',
    title: 'Bezpečí a jistota',
    subNeeds: [
      'Struktura',
      'Klid, duševní klid',
      'Psychické bezpečí',
      'Stabilita',
      'Důvěra',
      'Jistota',
      'Řád',
      'Předvídatelnost',
    ],
    icon: <HomeIcon />,
  },
  {
    id: '3',
    title: 'Čestnost',
    subNeeds: [
      'Autenticita',
      'Upřímnost',
      'Transparentnost',
      'Otevřenost',
      'Spravedlnost',
      'Férovost',
      'Integrita (soulad mezi tím, co říkám, dělám a co si myslím)',
    ],
    icon: <HandshakeIcon />,
  },
];

const rows = Array.from({ length: 10 }, () => ({
  message: faker.lorem.paragraph(),
  lecture: faker.word.noun(),
}));

export const FeedbackStatistics = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={onTabChange} aria-label="basic tabs example">
          <Tab label="Jednotlivé lekce" />
          <Tab label="Sourhn" />
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        <Box mb={0}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <b>Anglický jazyk</b>
          </Typography>

          <Typography fontSize={14}>20.9.2022</Typography>
        </Box>

        <Box mb={3}>
          <Typography>Lektor: Jan Novák</Typography>
        </Box>

        <Box mb={2}>
          <Typography style={{ fontSize: 18 }}>
            <b>Zvolené pozitivní pocity</b>
          </Typography>
        </Box>

        <Box mb={3}>
          <SelectedEmotions items={emotions} />
          {/*{emotions.map((emotion, i) => (*/}
          {/*  <Box mb={2} key={i}>*/}
          {/*    <ButtonBox title={emotion.title} color={emotion.color} selected={false} />*/}
          {/*  </Box>*/}
          {/*))}*/}
        </Box>

        <Box mb={2}>
          <Typography style={{ fontSize: 18 }}>
            <b>Zvolené naplněné potřeby</b>
          </Typography>
        </Box>

        <Box mb={3}>
          {needs.map((need, i) => (
            <Box mb={2} key={i}>
              <IconButtonBox title={need.title} content={need.subNeeds.join(', ')} icon={need.icon} />
            </Box>
          ))}

          {/*{needs.map((need, i) => (*/}
          {/*  <Box mb={2} key={i}>*/}
          {/*    <IconButtonBox title={need.title} icon={need.icon} />*/}
          {/*  </Box>*/}
          {/*))}*/}
        </Box>

        <Box mb={2}>
          <Typography style={{ fontSize: 18 }}>
            <b>Vyplněný feedback</b>
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography>
            Lekce byla naprosto super, když bychom se více zapojovali jako nyní, bude to velmi přínosné.
          </Typography>
        </Box>
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
          <b>Pocity po kvadrantech</b>
        </Typography>

        <BubbleChart maxR={5} />

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
          <b>Přehled naplněných a nenaplněných potřeb</b>
        </Typography>

        <BarChart data={barChartMixedNeedsData} options={{ min: -100, max: 100 }} />

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
          <b>Přehled zhodnocení</b>
        </Typography>

        {rows.map((row, i) => (
          <div key={i}>
            <Typography style={{ fontSize: 18 }}>
              <b>Název přednášky {i + 1}</b>
            </Typography>
            <Typography mb={1} style={{ fontSize: 14 }}>
              20.&nbsp;9.&nbsp;2022
            </Typography>
            <Typography mb={3}>{row.message}</Typography>
          </div>
        ))}
      </TabPanel>
    </>
  );
};
