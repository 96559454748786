import * as React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { emotionColors } from '../utils/data';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

interface Props {
  items: string[];
  scrollable?: boolean;
  onDelete?: (feelingId: string) => void;
}

export const SelectedEmotions = ({ items, scrollable, onDelete }: Props) => {
  const { appContext } = useContext(AppContext);

  const emotionsById = appContext.feelings.feelingsById;

  const content =
    items.length > 0 ? (
      items.map((feelingId, i) => {
        const feeling = emotionsById[feelingId];

        return (
          <div style={{ marginRight: 8, marginBottom: 8, display: 'inline-block' }} key={feelingId}>
            <Chip
              label={feeling.name}
              variant="outlined"
              onDelete={
                !!onDelete
                  ? () => {
                      !!onDelete && onDelete(feelingId);
                    }
                  : undefined
              }
              style={{
                background: `rgba(${emotionColors[feeling.type].bg}, 1)`,
                color: emotionColors[feeling.type].text,
              }}
            />
          </div>
        );
      })
    ) : (
      <Typography>Zatím nejsou zvolné žádné pocity.</Typography>
    );

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Typography>
          <b>Zvolené pocity</b>
        </Typography>
      </Box>

      {scrollable ? (
        <div style={{ overflow: 'auto', height: 35 }}>
          <Stack direction="row" spacing={1}>
            {content}
          </Stack>
        </div>
      ) : (
        content
      )}
    </Box>
  );
};
