import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  isSubmitting?: boolean;
}

export const StyledButton: FC<ButtonProps & Props> = ({ children, isSubmitting, ...props }) => {
  return (
    <Button {...props} variant="contained">
      {isSubmitting ? <CircularProgress style={{ color: '#fff' }} size={24} /> : children}
    </Button>
  );
};
