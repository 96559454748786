import * as React from 'react';
import { Typography, useTheme } from '@mui/material';

interface Props {
  title: string;
  color?: string;
  selected: boolean;
  onClick?: () => void;
}

export const ButtonBox = ({ title, color, selected, onClick }: Props) => {
  const colorDotSize = 30;
  const theme = useTheme();

  return (
    <div
      style={{
        border: `${selected ? `1px solid ${theme.palette.primary.main}` : '1px solid rgba(0, 0, 0, 0.1)'}`,
        borderRadius: 8,
        padding: 12,
      }}
      onClick={onClick}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography maxWidth={'80%'}>{title}</Typography>

          {!!color && (
              <div style={{ background: color, borderRadius: '50%', width: colorDotSize, height: colorDotSize }}></div>
          )}
      </div>
    </div>
  );
};
