import * as React from 'react';
import { Box, Paper, Typography } from '@mui/material';

interface Props {
  title: string;
  content: string;
}

export const StatisticsCard = ({ title, content }: Props) => (
  <Paper elevation={3} style={{ width: '25%' }}>
    <Box p={3}>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
        <b>{title}</b>
      </Typography>

      <Typography style={{ fontSize: 20 }}>{content}</Typography>
    </Box>
  </Paper>
);
