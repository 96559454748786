import * as React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { FeelingsDialog } from '@/components/FeelingsDialog';
import { SelectedEmotions } from '@/components/SelectedEmotions';
import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@/routes';
import { FeedbackFormHeader } from '@/components/FeedbackFormHeader';
import { FeelingQuadrant } from './components/FeelingQuadrant';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useLectureByLinkCode } from '@/hooks/api/useLectureByLinkCode';
import { FullscreenSpinner } from '@/components/FullscreenSpinner';
import { MessageDialog } from '@/components/MessageDialog';

export const FeedbackFormPage1Page = () => {
  const [type, setType] = useState<string | null>(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const { selectedFeelings, updateFeelings } = useFeedbackDataContext();

  const linkCode = useLinkCodeParam();

  const navigate = useNavigate();

  const { isLoading, error, data } = useLectureByLinkCode(linkCode);

  const location = useLocation();

  useEffect(() => {
    // redirect to last page if feedback has been submitted
    if (
      data?.data.isExistingFeedback &&
      (location.pathname.indexOf(routes.feedbackFormPage1('')) > -1 ||
        location.pathname.indexOf(routes.feedbackFormPage2('')) > -1 ||
        location.pathname.indexOf(routes.feedbackFormPage3('')) > -1 ||
        location.pathname.indexOf(routes.feedbackFormPage2b('')) > -1)
    ) {
      navigate(routes.feedbackFormPage4(linkCode));
    }
  }, [location, data]);

  const handleClickOpen = (type: string) => {
    setType(type);
  };

  const handleClose = () => {
    setType(null);
  };

  const toggleSelectedFeeling = (feelingId: string) => {
    updateFeelings(feelingId);
  };

  const open = type !== null;

  if (error) {
    return <Typography>Lekce nenalezena</Typography>;
  }

  if (isLoading || !data) {
    return <FullscreenSpinner />;
  }

  const lecture = data.data;

  const onNextStepClick = () => {
    if (selectedFeelings.length > 0) {
      navigate(routes.feedbackFormPage2(linkCode));
    } else {
      setErrorDialogOpen(true);
    }
  };

  return (
    <>
      <FeelingsDialog
        open={open}
        handleClose={handleClose}
        type={type}
        toggleSelectedFeeling={toggleSelectedFeeling}
        selectedFeelings={selectedFeelings}
      />

      <MessageDialog
        message={'Vyplňte prosím alespoň jeden pocit.'}
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
        }}
      />

      <FeedbackFormHeader lectureName={lecture.name} activeStep={0} />

      <Box mb={2}>
        <Alert severity="info">
          Pro zvolení pocitů využijte proklik do jednotlivých kvadrantů podle intenzity a příjemnosti pocitů.
        </Alert>
      </Box>

      <Box mb={2}>
        <div style={{ display: 'flex' }}>
          <FeelingQuadrant emoji={'very-sad'} type={'intenseUnpleasant'} onClick={handleClickOpen} />
          <FeelingQuadrant emoji={'very-happy'} type={'intensePleasant'} onClick={handleClickOpen} />
        </div>
        <div style={{ display: 'flex' }}>
          <FeelingQuadrant emoji={'sad'} type={'notIntenseUnpleasant'} onClick={handleClickOpen} />
          <FeelingQuadrant emoji={'happy'} type={'notIntensePleasant'} onClick={handleClickOpen} />
        </div>
      </Box>

      {/*<Box mb={3}>*/}
      {/*  <div style={{ display: 'flex', gap: 16 }}>*/}
      {/*    <div style={{ width: '50%', height: 110 }}>*/}
      {/*      <div style={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: 10, padding: 8 }} onClick={() => { handleClickOpen('intenseUnpleasant') }}>*/}
      {/*        <div style={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', background: 'rgba(199,8,8,0.5)', padding: 8, color: '#fff'  }}>*/}
      {/*          <Typography style={{ fontSize: 13 }}>*/}
      {/*            {emotions['intenseUnpleasant'].slice(0, 4).map((emotion, i) => <>{emotion.title}<br /></>)}*/}
      {/*          </Typography>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div style={{ width: '50%', height: 110 }}>*/}
      {/*      <div style={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: 10, padding: 8 }} onClick={() => { handleClickOpen('intensePleasant') }}>*/}
      {/*        <div style={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', background: 'rgba(255,229,45,0.5)', padding: 8  }}>*/}
      {/*          <Typography style={{ fontSize: 13 }}>*/}
      {/*            {emotions['intensePleasant'].slice(0, 4).map((emotion, i) => <>{emotion.title}<br /></>)}*/}
      {/*          </Typography>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div style={{ display: 'flex', marginTop: 16, gap: 16 }}>*/}
      {/*    <div style={{ width: '50%', height: 110 }}>*/}
      {/*      <div style={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: 10, padding: 8 }} onClick={() => { handleClickOpen('notIntenseUnpleasant') }}>*/}
      {/*        <div style={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', background: 'rgba(45,151,227,0.71)', padding: 8, color: '#fff'  }}>*/}
      {/*          <Typography style={{ fontSize: 13 }}>*/}
      {/*            {emotions['notIntenseUnpleasant'].slice(0, 4).map((emotion, i) => <>{emotion.title}<br /></>)}*/}
      {/*          </Typography>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div style={{ width: '50%', height: 110 }}>*/}
      {/*      <div style={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: 10, padding: 8 }} onClick={() => { handleClickOpen('notIntensePleasant') }}>*/}
      {/*        <div style={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', background: 'rgba(45,227,136,0.5)', padding: 8  }}>*/}
      {/*          <Typography style={{ fontSize: 13 }}>*/}
      {/*            {emotions['notIntensePleasant'].slice(0, 4).map((emotion, i) => <>{emotion.title}<br /></>)}*/}
      {/*          </Typography>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Box>*/}

      {/*<Box mb={3}>*/}
      {/*  <TextField id="outlined-basic" label="Vyhledejte název pocitu" variant="outlined" fullWidth />*/}
      {/*</Box>*/}

      <SelectedEmotions items={selectedFeelings} onDelete={toggleSelectedFeeling} />

      {/*<Box mb={3}>*/}
      {/*  <Divider />*/}
      {/*</Box>*/}

      {/*<Box mb={3}>*/}
      {/*  <Typography><b>Přehled všech pocitů</b></Typography>*/}
      {/*</Box>*/}

      {/*{emotions.map((emotion, i) => (*/}
      {/*  <Box mb={2} key={i}>*/}
      {/*    <ButtonBox title={emotion.title} color={emotion.color} />*/}
      {/*  </Box>*/}
      {/*))}*/}

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={onNextStepClick}>
          Pokračovat
        </Button>
      </div>
    </>
  );
};
