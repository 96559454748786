import * as React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { StyledTableCell, StyledTableRow } from '../../components/Table';

export const SeminarsListPage = () => {
  const seminars = [
    {
      name: 'Didaktika',
    },
    {
      name: 'Reflexe',
    },
    {
      name: 'Angličtina',
    },
  ];

  return (
    <>
      <div style={{ margin: 'auto', width: 1600 }}>
        <Paper elevation={3}>
          <Box p={3}>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} mb={3}>
              <b>Seznam seminářů</b>
            </Typography>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>
                      <b>Název semináře</b>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <b>Akce</b>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {seminars.map((seminar, i) => (
                    <StyledTableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {seminar.name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        <EditIcon /> <QueryStatsIcon />
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </div>
    </>
  );
};
