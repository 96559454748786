import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const FullscreenSpinner = () => (
  <Box
    style={{
      margin: 'auto',
      height: 'calc(100vh - 80px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress />
  </Box>
);
