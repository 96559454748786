import * as React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { BubbleChart } from '../../components/BubbleChart';
import { BarChart } from '../../components/BarChart';
import { barChartMixedNeedsData } from '../../utils/fixtures';
import { TextRatingTable } from '../admin-statistics/components/TextRatingTable';
import { StatisticsCard } from '../../components/StatisticsCard';

export const LectureDetailPage = () => (
  <>
    <div style={{ margin: 'auto', width: 1600 }}>
      <Paper elevation={3}>
        <Box p={3}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} mb={3}>
            <b>Didaktita 20. 9. 2022</b>
          </Typography>

          <Box style={{ display: 'flex', gap: 20, flex: '1 1 0px' }} mb={3}>
            <StatisticsCard title={'Počet zaznamenaných pocitů'} content={'120'} />
            <StatisticsCard title={'Počet naplněných potřeb'} content={'120'} />
            <StatisticsCard title={'Počet nenaplněných potřeb'} content={'120'} />
            <StatisticsCard title={'Počet textových zhodnocení'} content={'120'} />
          </Box>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
            <b>Pocity po kvadrantech</b>
          </Typography>

          <BubbleChart maxR={20} />

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
            <b>Přehled naplněných a nenaplněných potřeb</b>
          </Typography>

          <BarChart data={barChartMixedNeedsData} options={{ min: -100, max: 100 }} />

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
            <b>Textová zhodnocení</b>
          </Typography>

          <TextRatingTable />
        </Box>
      </Paper>
    </div>
  </>
);
