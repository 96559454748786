import * as React from 'react';
import {
  Autocomplete,
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { BubbleChart } from '../../components/BubbleChart';
import { LineChart } from '../../components/LineChart';
import { PieDataChart } from '../../components/PieDataChart';
import {
  barChartFullFilleddNeedsData,
  barChartMixedNeedsData,
  barChartNegativeData,
  barChartPositiveData,
  barChartUnfullFilleddNeedsData,
  lineChartData,
  pieChartFeelingsData,
  pieChartFeelingsDataDetailed,
  pieChartNeedsData,
} from '../../utils/fixtures';
import { BarChart } from '../../components/BarChart';
import { TextRatingTable } from './components/TextRatingTable';
import { StyledTableCell, StyledTableRow } from '../../components/Table';
import { faker } from '@faker-js/faker';
import { AddTask, Flatware, ThumbUpOffAlt } from '@mui/icons-material';
import { TabPanel } from '../../components/TabPanel';
import { HeatMapChart } from '../../components/HeatMapChart';

const seminars = [
  {
    label: 'Didaktika',
    id: 1,
  },
  {
    label: 'Reflexe',
    id: 2,
  },
  {
    label: 'Angličtina',
    id: 2,
  },
];

const periods = [
  {
    label: 'Posledních 7 dní',
    id: 2,
  },
  {
    label: 'Posledních 14 dní',
    id: 2,
  },
  {
    label: 'Posledních 30 dní',
    id: 2,
  },
  {
    label: 'Posledních 3 měsíce',
    id: 2,
  },
  {
    label: 'Posledních 6 měsíců',
    id: 2,
  },
  {
    label: 'Posledních rok',
    id: 2,
  },
];

const positiveNeedsList = [
  {
    title: 'Jídlo',
    icon: <Flatware />,
  },
  {
    title: 'Uznání',
    icon: <ThumbUpOffAlt />,
  },
  {
    title: 'Angažovanost',
    icon: <AddTask />,
  },
];

export const StatisticsPage = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <div style={{ margin: 'auto', width: 2000 }}>
        <Paper elevation={3}>
          <Box p={3}>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} mb={3}>
              <b>Statistiky</b>
            </Typography>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
              <b>Filtry</b>
            </Typography>

            <Box mb={3} style={{ display: 'flex', alignItems: 'center' }}>
              <Box mr={2}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={seminars}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Seminář" />}
                />
              </Box>
              <Box>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={periods}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Období" />}
                />
              </Box>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={onTabChange} aria-label="basic tabs example">
                <Tab label="Sourhn" />
                <Tab label="Pocity" />
                <Tab label="Potřeby" />
                <Tab label="Textová zhodnocení" />
              </Tabs>
            </Box>

            <TabPanel value={activeTab} index={0}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
                <b>Pocity po kvadrantech</b>
              </Typography>

              {/*<FeelingsBarChart />*/}

              <HeatMapChart fixedColors={false} includeCounts={true} />

              {/*<BubbleChart maxR={50} />*/}

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                <b>Přehled naplněných a nenaplněných potřeb</b>
              </Typography>

              <BarChart data={barChartMixedNeedsData} options={{ min: -100, max: 100 }} />

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                <b>Textová zhodnocení</b>
              </Typography>

              <TextRatingTable />
            </TabPanel>

            <TabPanel value={activeTab} index={1}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={2}>
                <b>Přehled pozitivních pocitů</b>
              </Typography>

              <Box mb={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Pocit</StyledTableCell>
                        <StyledTableCell align="right">Počet záznamů</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {Array.from({ length: 10 }, (_, index) => (
                        <StyledTableRow hover key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                style={{
                                  background: faker.color.rgb(),
                                  borderRadius: '50%',
                                  width: 30,
                                  height: 30,
                                  marginRight: 8,
                                }}
                              ></div>{' '}
                              Pocit {index + 1}
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {(10 - index) * 10}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={2}>
                <b>Přehled negativních pocitů</b>
              </Typography>

              <Box mb={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Pocit</StyledTableCell>
                        <StyledTableCell align="right">Počet záznamů</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {Array.from({ length: 10 }, (_, index) => (
                        <StyledTableRow hover key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                style={{
                                  background: faker.color.rgb(),
                                  borderRadius: '50%',
                                  width: 30,
                                  height: 30,
                                  marginRight: 8,
                                }}
                              ></div>{' '}
                              Pocit {index + 1}
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {(10 - index) * 10}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
                <b>Pocity po kvadrantech</b>
              </Typography>

              <BubbleChart maxR={20} />

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                Porovnání pozitivních pocitů
              </Typography>

              <BarChart data={barChartPositiveData} options={{ min: 0, max: 100 }} />

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                Porovnání negativních pocitů
              </Typography>

              <BarChart data={barChartNegativeData} options={{ min: 0, max: 100 }} />

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                <b>Vývoj typů pocitů v čase</b>
              </Typography>

              <LineChart data={lineChartData} />

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                <b>Zastoupnení pocitů</b>
              </Typography>

              <div style={{ display: 'flex', verticalAlign: 'center', justifyContent: 'space-between' }}>
                <div style={{ width: '40%' }}>
                  <PieDataChart data={pieChartFeelingsData} />
                </div>
                <div style={{ width: '40%' }}>
                  <PieDataChart data={pieChartFeelingsDataDetailed} />
                </div>
              </div>
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={2}>
                <b>Přehled naplněných potřeb</b>
              </Typography>

              <Box mb={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Potřeba</StyledTableCell>
                        <StyledTableCell align="right">Počet záznamů</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {positiveNeedsList.map((need, i) => (
                        <StyledTableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Box mr={1}>{need.icon}</Box> {need.title}
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {(10 - i) * 10}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={2} mt={5}>
                <b>Přehled nenaplněných potřeb</b>
              </Typography>

              <Box mb={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Potřeba</StyledTableCell>
                        <StyledTableCell align="right">Počet záznamů</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {positiveNeedsList.slice(0, 2).map((need, i) => (
                        <StyledTableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Box mr={1}>{need.icon}</Box> {need.title}
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {(10 - i) * 10}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                <b>Přehled naplněných a nenaplněných potřeb</b>
              </Typography>

              <BarChart data={barChartMixedNeedsData} options={{ min: -100, max: 100 }} />

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                Porovnání naplněných potřeb
              </Typography>

              <BarChart data={barChartFullFilleddNeedsData} options={{ min: 0, max: 100 }} indexAxis={'y'} />

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                Porovnání nenaplněných potřeb
              </Typography>

              <BarChart data={barChartUnfullFilleddNeedsData} options={{ min: 0, max: 100 }} indexAxis={'y'} />

              <div style={{ display: 'flex', verticalAlign: 'center', justifyContent: 'space-between' }}>
                <div style={{ width: '40%' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                    <b>Zastoupení naplněných potřeb</b>
                  </Typography>

                  <PieDataChart data={pieChartNeedsData()} />
                </div>
                <div style={{ width: '40%' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
                    <b>Zastoupení nenaplněných potřeb</b>
                  </Typography>

                  <PieDataChart data={pieChartNeedsData()} />
                </div>
              </div>

              {/*<Typography variant="h6" component="div" sx={{flexGrow: 1}} mb={3} mt={5}>*/}
              {/*  <b>Vývoj naplněných potřeb v čase</b>*/}
              {/*</Typography>*/}

              {/*<BarChart data={barChartFullFilleddNeedsData} options={{ min: 0, max: 100 }} />*/}

              {/*<Typography variant="h6" component="div" sx={{flexGrow: 1}} mb={3} mt={5}>*/}
              {/*  <b>Vývoj nenaplněných potřeb v čase</b>*/}
              {/*</Typography>*/}

              {/*<BarChart data={barChartUnfullFilleddNeedsData} options={{ min: -100, max: 0 }} />*/}
            </TabPanel>

            <TabPanel index={3} value={activeTab}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
                <b>Přehled zhodnocení</b>
              </Typography>

              <TextRatingTable />
            </TabPanel>
          </Box>
        </Paper>
      </div>
    </>
  );
};
