import React from 'react';
import { Feeling } from '@/types/feeling';
import { groupArray, indexArray } from '@/services/array';
import { Flatware } from '@mui/icons-material';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import SpaIcon from '@mui/icons-material/Spa';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HomeIcon from '@mui/icons-material/Home';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AddTaskIcon from '@mui/icons-material/AddTask';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import ExtensionIcon from '@mui/icons-material/Extension';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

export const months = [
  'Leden',
  'Únor',
  'Březen',
  'Duben',
  'Květen',
  'Červen',
  'Červenec',
  'Srpen',
  'Září',
  'Říjen',
  'Listopad',
  'Prosinec',
];

export const needIcons = {
  HomeIcon: <HomeIcon />,
  Flatware: <Flatware />,
  HandshakeIcon: <HandshakeIcon />,
  AccessibilityNewIcon: <AccessibilityNewIcon />,
  ExtensionIcon: <ExtensionIcon />,
  SpaIcon: <SpaIcon />,
  VolunteerActivismIcon: <VolunteerActivismIcon />,
  FavoriteBorderIcon: <FavoriteBorderIcon />,
  FollowTheSignsIcon: <FollowTheSignsIcon />,
  AddTaskIcon: <AddTaskIcon />,
  Diversity3Icon: <Diversity3Icon />,
};

// export const needs: Need[] = [
//   {
//     id: '1',
//     title: 'Bezpečí a jistota',
//     subNeeds: [
//       'Struktura',
//       'Klid, Duševní klid',
//       'Psychické bezpečí',
//       'Stabilita',
//       'Důvěra',
//       'Jistota',
//       'Řád',
//       'Předvídatelnost',
//     ],
//     icon: <HomeIcon />,
//   },
//   {
//     id: '2',
//     title: 'Fyziologické potřeby',
//     subNeeds: ['Odpočinek', 'Pohyb', 'Vzduch', 'Jídlo', 'Dotek', 'Voda', 'Fyzické bezpečí'],
//     icon: <Flatware />,
//   },
//   {
//     id: '3',
//     title: 'Čestnost',
//     subNeeds: [
//       'Autenticita',
//       'Upřímnost',
//       'Transparentnost',
//       'Otevřenost',
//       'Spravedlnost',
//       'Férovost',
//       'Integrita (soulad mezi tím, co říkám, dělám a co si myslím)',
//     ],
//     icon: <HandshakeIcon />,
//   },
//   {
//     id: '4',
//     title: 'Autonomie',
//     subNeeds: ['Svoboda', 'Nezávislost', 'Prostor', 'Spontaneita', 'Volba', 'Moc', 'Vlastní odpovědnost', 'Volnost'],
//     icon: <AccessibilityNewIcon />,
//   },
//   {
//     id: '5',
//     title: 'Hravost',
//     icon: <ExtensionIcon />,
//     subNeeds: ['Humor', 'Hra', 'Radost', 'Soutěžení', 'Zábava'],
//   },
//   {
//     id: '6',
//     title: 'Vnitřní klid',
//     subNeeds: [
//       'Krása',
//       'Společenství',
//       'Harmonie',
//       'Inspirace',
//       'Pořádek',
//       'Rovnost',
//       'Soulad',
//       'Lehkost',
//       'Přítomnost (bytí tady a teď)',
//     ],
//     icon: <SpaIcon />,
//   },
//   {
//     id: '7',
//     title: 'Náklonnost',
//     subNeeds: [
//       'Náklonnost',
//       'Blízkost',
//       'Něha',
//       'Podpora, pomoc',
//       'Pozornost',
//       'Ocenění, Uznání',
//       'Láska',
//       'Intimita - možnost sdílet osobní prožitky',
//     ],
//     icon: <VolunteerActivismIcon />,
//   },
//   {
//     id: '8',
//     title: 'Respekt, úcta, důstojnost',
//     icon: <FavoriteBorderIcon />,
//     subNeeds: [
//       'Být přijímán',
//       'Chápat a být pochopen',
//       'Empatie, Pochopení',
//       'Péče',
//       'Respekt, Úcta, Důstojnost',
//       'Soucit',
//       'Přijetí',
//       'Vidět a být viděn',
//       'Laskavost',
//       'Důvěra',
//       'Být vnímán',
//     ],
//   },
//   {
//     id: '9',
//     title: 'Smysl',
//     icon: <FollowTheSignsIcon />,
//     subNeeds: [
//       'Výzva',
//       'Kreativita',
//       'Efektivita, Progres',
//       'Smysl/účel',
//       'Tvořivost',
//       'Sebevyjádření',
//       'Kompetence',
//       'Růst',
//       'Učení',
//       'Rozvoj',
//       'Přispění (dávám a přispívám ostatním)',
//       'Výzva, Dobrodružství',
//     ],
//   },
//   {
//     id: '10',
//     title: 'Srozumitelnost, pochopení',
//     subNeeds: ['Jasnost, Orientace (mít jasno, orientovat se)', 'Porozumění, Vědění (vědět, být informován, rozumět)'],
//     icon: <AddTaskIcon />,
//   },
//   {
//     id: '11',
//     title: 'Společenství',
//     subNeeds: ['Partnerství, Přátelství', 'Sdílení', 'Spolupráce', 'Komunikace', 'Sounáležitost'],
//     icon: <Diversity3Icon />,
//   },
// ];
//
// export const needsById = indexArray(needs, (need) => need.id);

interface FeelingColor {
  bg: string;
  text: string;
  rgbaAlpha: (x: number, y: number) => number;
}

const maxX = 4;
const maxY = 5;

const xIncIntenseUnpleasant = 0.05;
const yIncIntenseUnpleasant = 0.08;

const xIncIntensePleasant = 0.1;
const yIncIntensePleasant = 0.125;

const xIncNotIntensePleasant = 0.05;
const yIncNotIntensePleasant = 0.08;

const xIncNotIntenseUnpleasant = 0.05;
const yIncNotIntenseUnpleasant = 0.08;

// 255,229,45

// 250,220,20

// export const emotionColors: Record<string, FeelingColor> = {
//   intenseUnpleasant: { bg: '199,8,8', text: '#fff', rgbaAlpha: (x: number, y: number) => 1 - (x * xIncIntenseUnpleasant + y * yIncIntenseUnpleasant) },
//   intensePleasant:  { bg: '255,224,10', text: '#000', rgbaAlpha: (x: number, y: number) => (1 - ((maxX - 1) * xIncIntensePleasant + y * yIncIntensePleasant)) + (x * xIncIntensePleasant) },
//   notIntenseUnpleasant:  { bg: '45,151,227', text: '#fff', rgbaAlpha: (x: number, y: number) => 1 - ((maxY - 1) * yIncNotIntenseUnpleasant + x * xIncNotIntenseUnpleasant) + (y * yIncNotIntenseUnpleasant) },
//   notIntensePleasant:  { bg: '45,227,136', text: '#000', rgbaAlpha: (x: number, y: number) => (1 - ((maxX - 1) * xIncNotIntensePleasant + (maxY - 1) * yIncNotIntensePleasant)) + (x * xIncNotIntensePleasant + y * yIncNotIntensePleasant) },
// };

export const emotionColors: Record<string, FeelingColor> = {
  intenseUnpleasant: {
    bg: '199,8,8',
    text: '#fff',
    rgbaAlpha: (x: number, y: number) => 1 - (x * xIncIntenseUnpleasant + y * yIncIntenseUnpleasant),
  },
  intensePleasant: {
    bg: '255,224,10',
    text: '#000',
    rgbaAlpha: (x: number, y: number) =>
      1 - ((maxX - 1) * xIncIntensePleasant + y * yIncIntensePleasant) + x * xIncIntensePleasant,
  },
  notIntenseUnpleasant: {
    bg: '45,151,227',
    text: '#fff',
    rgbaAlpha: (x: number, y: number) =>
      1 - ((maxY - 1) * yIncNotIntenseUnpleasant + x * xIncNotIntenseUnpleasant) + y * yIncNotIntenseUnpleasant,
  },
  notIntensePleasant: {
    bg: '45,227,136',
    text: '#000',
    rgbaAlpha: (x: number, y: number) =>
      1 -
      ((maxX - 1) * xIncNotIntensePleasant + (maxY - 1) * yIncNotIntensePleasant) +
      (x * xIncNotIntensePleasant + y * yIncNotIntensePleasant),
  },
};

export const emotionColorsAlternative: Record<string, FeelingColor> = {
  intenseUnpleasant: {
    bg: '255,141,141',
    text: '#fff',
    rgbaAlpha: (x: number, y: number) => 1 - (x * xIncIntenseUnpleasant + y * yIncIntenseUnpleasant),
  },
  intensePleasant: {
    bg: '255,235,95',
    text: '#000',
    rgbaAlpha: (x: number, y: number) =>
      1 - ((maxX - 1) * xIncIntensePleasant + y * yIncIntensePleasant) + x * xIncIntensePleasant,
  },
  notIntenseUnpleasant: {
    bg: '110,193,253',
    text: '#fff',
    rgbaAlpha: (x: number, y: number) =>
      1 - ((maxY - 1) * yIncNotIntenseUnpleasant + x * xIncNotIntenseUnpleasant) + y * yIncNotIntenseUnpleasant,
  },
  notIntensePleasant: {
    bg: '124,255,189',
    text: '#000',
    rgbaAlpha: (x: number, y: number) =>
      1 -
      ((maxX - 1) * xIncNotIntensePleasant + (maxY - 1) * yIncNotIntensePleasant) +
      (x * xIncNotIntensePleasant + y * yIncNotIntensePleasant),
  },
};

export const emotionColorsAlternative2: Record<string, FeelingColor> = {
  intenseUnpleasant: {
    bg: '255,115,115',
    text: '#fff',
    rgbaAlpha: (x: number, y: number) => 1 - (x * xIncIntenseUnpleasant + y * yIncIntenseUnpleasant),
  },
  intensePleasant: {
    bg: '255,235,95',
    text: '#000',
    rgbaAlpha: (x: number, y: number) =>
      1 - ((maxX - 1) * xIncIntensePleasant + y * yIncIntensePleasant) + x * xIncIntensePleasant,
  },
  notIntenseUnpleasant: {
    bg: '110,193,253',
    text: '#fff',
    rgbaAlpha: (x: number, y: number) =>
      1 - ((maxY - 1) * yIncNotIntenseUnpleasant + x * xIncNotIntenseUnpleasant) + y * yIncNotIntenseUnpleasant,
  },
  notIntensePleasant: {
    bg: '124,255,189',
    text: '#000',
    rgbaAlpha: (x: number, y: number) =>
      1 -
      ((maxX - 1) * xIncNotIntensePleasant + (maxY - 1) * yIncNotIntensePleasant) +
      (x * xIncNotIntensePleasant + y * yIncNotIntensePleasant),
  },
};

export const emotionsData: Feeling[] = [
  {
    id: '1',
    name: 'Rozzuření',
    type: 'intenseUnpleasant',
  },
  {
    id: '2',
    name: 'Panika',
    type: 'intenseUnpleasant',
  },
  {
    id: '3',
    name: 'Stres',
    type: 'intenseUnpleasant',
  },
  {
    id: '4',
    name: 'Strach',
    type: 'intenseUnpleasant',
  },
  {
    id: '5',
    name: 'Rozčílení',
    type: 'intenseUnpleasant',
  },
  {
    id: '6',
    name: 'Frustrace',
    type: 'intenseUnpleasant',
  },
  {
    id: '7',
    name: 'Netrpělivost',
    type: 'intenseUnpleasant',
  },
  {
    id: '8',
    name: 'Zahlcení',
    type: 'intenseUnpleasant',
  },
  {
    id: '9',
    name: 'Vyděšení',
    type: 'intenseUnpleasant',
  },
  {
    id: '10',
    name: 'Hořkost',
    type: 'intenseUnpleasant',
  },
  {
    id: '11',
    name: 'Nervozita',
    type: 'intenseUnpleasant',
  },
  {
    id: '12',
    name: 'Podráždění',
    type: 'intenseUnpleasant',
  },
  {
    id: '13',
    name: 'Úzkost',
    type: 'intenseUnpleasant',
  },
  {
    id: '14',
    name: 'Naštvání',
    type: 'intenseUnpleasant',
  },
  {
    id: '15',
    name: 'Stud',
    type: 'intenseUnpleasant',
  },
  {
    id: '16',
    name: 'Otrávenost',
    type: 'intenseUnpleasant',
  },
  {
    id: '17',
    name: 'Obavy',
    type: 'intenseUnpleasant',
  },
  {
    id: '18',
    name: 'Ustaranost',
    type: 'intenseUnpleasant',
  },
  {
    id: '19',
    name: 'Zmatenost',
    type: 'intenseUnpleasant',
  },
  {
    id: '20',
    name: 'Neklid',
    type: 'intenseUnpleasant',
  },
  {
    id: '21',
    name: 'Překvapení',
    type: 'intensePleasant',
  },
  {
    id: '22',
    name: 'Rozpustilost',
    type: 'intensePleasant',
  },
  {
    id: '23',
    name: 'Fascinace',
    type: 'intensePleasant',
  },
  {
    id: '24',
    name: 'Excitovanost',
    type: 'intensePleasant',
  },
  {
    id: '25',
    name: 'Odhodlání',
    type: 'intensePleasant',
  },
  {
    id: '26',
    name: 'Zvědavost',
    type: 'intensePleasant',
  },
  {
    id: '27',
    name: 'Motivace',
    type: 'intensePleasant',
  },
  {
    id: '28',
    name: 'Aktivizace',
    type: 'intensePleasant',
  },
  {
    id: '29',
    name: 'Energie',
    type: 'intensePleasant',
  },
  {
    id: '30',
    name: 'Pobavení',
    type: 'intensePleasant',
  },
  {
    id: '31',
    name: 'Inspirace',
    type: 'intensePleasant',
  },
  {
    id: '32',
    name: 'Vášeň',
    type: 'intensePleasant',
  },
  {
    id: '33',
    name: 'Potěšení',
    type: 'intensePleasant',
  },
  {
    id: '34',
    name: 'Hřejivý pocit',
    type: 'intensePleasant',
  },
  {
    id: '35',
    name: 'Hrdost',
    type: 'intensePleasant',
  },
  {
    id: '36',
    name: 'Nadšení',
    type: 'intensePleasant',
  },
  {
    id: '37',
    name: 'Dobrá nálada',
    type: 'intensePleasant',
  },
  {
    id: '38',
    name: 'Radost',
    type: 'intensePleasant',
  },
  {
    id: '39',
    name: 'Naděje',
    type: 'intensePleasant',
  },
  {
    id: '40',
    name: 'Hravost',
    type: 'intensePleasant',
  },
  {
    id: '41',
    name: 'Znechucení',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '42',
    name: 'Zklamání',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '43',
    name: 'Zachmuřelost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '44',
    name: 'Apatie',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '45',
    name: 'Opuštěnost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '46',
    name: 'Odrazenost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '47',
    name: 'Lítost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '48',
    name: 'Znudění',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '49',
    name: 'Sklíčenost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '50',
    name: 'Osamělost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '51',
    name: 'Smutek',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '52',
    name: 'Ospalost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '53',
    name: 'Zoufalost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '54',
    name: 'Beznaděj',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '55',
    name: 'Zranění',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '56',
    name: 'Únava',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '57',
    name: 'Bezmoc',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '58',
    name: 'Rezignovanost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '59',
    name: 'Lhostejnost',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '60',
    name: 'Vyčerpání',
    type: 'notIntenseUnpleasant',
  },
  {
    id: '61',
    name: 'Spokojenost',
    type: 'notIntensePleasant',
  },
  {
    id: '62',
    name: 'Pohoda',
    type: 'notIntensePleasant',
  },
  {
    id: '63',
    name: 'Láska',
    type: 'notIntensePleasant',
  },
  {
    id: '64',
    name: 'Důvěra',
    type: 'notIntensePleasant',
  },
  {
    id: '65',
    name: 'Klid',
    type: 'notIntensePleasant',
  },
  {
    id: '66',
    name: 'Uspokojení',
    type: 'notIntensePleasant',
  },
  {
    id: '67',
    name: 'Něha',
    type: 'notIntensePleasant',
  },
  {
    id: '68',
    name: 'Dojetí',
    type: 'notIntensePleasant',
  },
  {
    id: '69',
    name: 'Citlivost',
    type: 'notIntensePleasant',
  },
  {
    id: '70',
    name: 'Pohodlí',
    type: 'notIntensePleasant',
  },
  {
    id: '71',
    name: 'Naplnění',
    type: 'notIntensePleasant',
  },
  {
    id: '72',
    name: 'Sebejistota',
    type: 'notIntensePleasant',
  },
  {
    id: '73',
    name: 'Relaxace',
    type: 'notIntensePleasant',
  },
  {
    id: '74',
    name: 'Volnost',
    type: 'notIntensePleasant',
  },
  {
    id: '75',
    name: 'Vděčnost',
    type: 'notIntensePleasant',
  },
  {
    id: '76',
    name: 'Bezstarostnost',
    type: 'notIntensePleasant',
  },
  {
    id: '77',
    name: 'Bezpečí',
    type: 'notIntensePleasant',
  },
  {
    id: '78',
    name: 'Uvolnění',
    type: 'notIntensePleasant',
  },
  {
    id: '79',
    name: 'Rovnováha',
    type: 'notIntensePleasant',
  },
  {
    id: '80',
    name: 'Vyrovnanost',
    type: 'notIntensePleasant',
  },
];

export const emotionsById: Record<string, Feeling> = indexArray(emotionsData, (feeling) => feeling.id);

export const emotions: Record<string, Feeling[]> = groupArray(emotionsData, (feeling) => feeling.type);
