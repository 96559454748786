import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { NeedType } from '../types/need';

export const useFeedbackDataContext = () => {
  const appContext = useContext(AppContext);

  const updateMoodIntensity = (moodIntensity: number) => {
    appContext.setAppContext((currentAppContext) => ({
      ...currentAppContext,
      feedbackData: {
        ...currentAppContext.feedbackData,
        moodIntensity,
      },
    }));
  }

  const updateFeelings = (feelingId: string) => {
    appContext.setAppContext((currentAppContext) => ({
      ...currentAppContext,
      feedbackData: {
        ...currentAppContext.feedbackData,
        feelingIds:
          currentAppContext.feedbackData.feelingIds.indexOf(feelingId) > -1
            ? currentAppContext.feedbackData.feelingIds.filter((fId) => fId !== feelingId)
            : [...currentAppContext.feedbackData.feelingIds, feelingId],
      },
    }));
  };

  const updateNeeds = (needId: string, type: NeedType) => {
    appContext.setAppContext((currentAppContext) => ({
      ...currentAppContext,
      feedbackData: {
        ...currentAppContext.feedbackData,
        needIds: {
          ...currentAppContext.feedbackData.needIds,
          [type]:
            currentAppContext.feedbackData.needIds[type].indexOf(needId) > -1
              ? currentAppContext.feedbackData.needIds[type].filter((nId) => nId !== needId)
              : [...currentAppContext.feedbackData.needIds[type], needId],
        },
      },
    }));
  };

  const updateCommentText = (text: string, type: 'positive' | 'negative') => {
    appContext.setAppContext((currentAppContext) => ({
      ...currentAppContext,
      feedbackData: {
        ...currentAppContext.feedbackData,
        comments: {
          ...currentAppContext.feedbackData.comments,
          [type]: {
            ...currentAppContext.feedbackData.comments[type],
            text,
          },
        },
      },
    }));
  };

  const toggleCommentType = (type: 'positive' | 'negative') => {
    appContext.setAppContext((currentAppContext) => ({
      ...currentAppContext,
      feedbackData: {
        ...currentAppContext.feedbackData,
        comments: {
          ...currentAppContext.feedbackData.comments,
          [type]: {
            ...currentAppContext.feedbackData.comments[type],
            checked: !currentAppContext.feedbackData.comments[type].checked,
          },
        },
      },
    }));
  };

  const updateFeelingsToNeeds = (feelingId: string, needId: string) => {
    appContext.setAppContext((currentAppContext) => ({
      ...currentAppContext,
      feedbackData: {
        ...currentAppContext.feedbackData,
        feelingsToNeeds: currentAppContext.feedbackData.feelingsToNeeds.map((feelingToNeed) =>
          feelingToNeed.feelingId === feelingId
            ? {
                ...feelingToNeed,
                needIds:
                  feelingToNeed.needIds.indexOf(needId) > -1
                    ? feelingToNeed.needIds.filter((nId) => nId !== needId)
                    : [...feelingToNeed.needIds, needId],
              }
            : feelingToNeed
        ),
      },
    }));
  };

  const toggleFeelingsToNeeds = (feelingId: string) => {
    appContext.setAppContext((currentAppContext) => ({
      ...currentAppContext,
      feedbackData: {
        ...currentAppContext.feedbackData,
        feelingsToNeeds:
          currentAppContext.feedbackData.feelingsToNeeds.filter(
            (feelingToNeed) => feelingToNeed.feelingId === feelingId
          ).length > 0
            ? currentAppContext.feedbackData.feelingsToNeeds.map((feelingToNeed) =>
                feelingToNeed.feelingId === feelingId
                  ? {
                      ...feelingToNeed,
                      expanded: !feelingToNeed.expanded,
                    }
                  : feelingToNeed
              )
            : [...currentAppContext.feedbackData.feelingsToNeeds, { feelingId, expanded: true, needIds: [] }],
      },
    }));
  };

  return {
    moodIntensity: appContext.appContext.feedbackData.moodIntensity,
    feedbackData: appContext.appContext.feedbackData,
    selectedFeelings: appContext.appContext.feedbackData.feelingIds,
    selectedNeeds: appContext.appContext.feedbackData.needIds,
    comments: appContext.appContext.feedbackData.comments,
    feelingsToNeeds: appContext.appContext.feedbackData.feelingsToNeeds,
    updateMoodIntensity,
    updateFeelings,
    updateNeeds,
    updateCommentText,
    toggleCommentType,
    updateFeelingsToNeeds,
    toggleFeelingsToNeeds,
  };
};
