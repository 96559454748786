import * as React from 'react';
import { Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  title: string;
  content?: string;
  icon: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

export const IconButtonBox = ({ title, content, icon, selected, onClick }: Props) => {
  const theme = useTheme();

  return (
    <div
      style={{
        border: `${selected ? `1px solid ${theme.palette.primary.main}` : '1px solid rgba(0, 0, 0, 0.1)'}`,
        borderRadius: 8,
        padding: 12,
      }}
      onClick={onClick}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/*justifyContent: 'space-between'*/}
        {!!icon && <div style={{ marginRight: 4 }}>{icon}</div>}
        <Typography style={{ lineHeight: 1 }}>{title}</Typography>
      </div>
      {!!content && (
        <>
          <br />
          <Typography style={{ fontSize: 13, color: 'gray' }}>{content}</Typography>
        </>
      )}
    </div>
  );
};

// Stat version

// export const IconButtonBox = ({ title, content, icon, selected, onClick }: Props) => {
//   const theme = useTheme();
//
//   return (
//     <div style={{ border: `${selected ? `1px solid ${theme.palette.primary.main}` : '2px solid rgba(0, 0, 0, 0.1)'}`, borderRadius: 8, padding: '45px 30px'}} onClick={onClick}>
//       <div style={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}>
//         {/*justifyContent: 'space-between'*/}
//         {!!icon && (
//           <div style={{ marginRight: 64, marginLeft: 16 }}>
//             {icon}
//           </div>
//         )}
//         <Typography style={{ lineHeight: 1, fontSize: 80 }}>{title}</Typography>
//       </div>
//       {/*{!!content && (*/}
//       {/*  <>*/}
//       {/*    <br />*/}
//       {/*    <Typography style={{ fontSize: 13, color: 'gray' }}>{content}</Typography>*/}
//       {/*  </>*/}
//       {/*)}*/}
//     </div>
//   );
// };
