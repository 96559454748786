import * as React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { NeedType } from '@/types/need';
import { useContext } from 'react';
import { AppContext } from '@/context/AppContext';

interface Props {
  caption: string;
  items: string[];
  scrollable?: boolean;
  deleteAction?: {
    type: NeedType;
    onDelete: (needId: string, type: NeedType) => void;
  };
}

export const SelectedNeeds = ({ caption, items, scrollable, deleteAction }: Props) => {
  const needsById = useContext(AppContext).appContext.needs.needsById;

  const content =
    items.length > 0 ? (
      items.map((needId, i) => {
        const need = needsById[needId];

        return (
          <div style={{ marginRight: 8, marginBottom: 8, display: 'inline-block' }} key={need.id}>
            <Chip
              label={need.name}
              icon={need.icon}
              variant="outlined"
              onDelete={
                !!deleteAction
                  ? () => {
                      deleteAction.onDelete(needId, deleteAction.type);
                    }
                  : undefined
              }
            />
          </div>
        );
      })
    ) : (
      <Typography>Zatím nejsou zvolné žádné potřeby.</Typography>
    );

  return (
    <Box mb={2}>
      {!!caption && (
        <Box mb={2}>
          <Typography>
            <b>{caption}</b>
          </Typography>
        </Box>
      )}

      {scrollable ? (
        <div style={{ overflow: 'auto', height: 35 }}>
          <Stack direction="row" spacing={1}>
            {content}
          </Stack>
        </div>
      ) : (
        content
      )}
    </Box>
  );
};
