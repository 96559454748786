import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, Typography } from '@mui/material';
import { IconButtonBox } from '@/components/IconButtonBox';
import { SelectedEmotions } from '@/components/SelectedEmotions';
import { routes } from '@/routes';
import { FeedbackFormHeader } from '@/components/FeedbackFormHeader';
import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useLectureByLinkCode } from '@/hooks/api/useLectureByLinkCode';
import { FullscreenSpinner } from '@/components/FullscreenSpinner';
import { AppContext } from '@/context/AppContext';
import { useFeedbackDataMutation } from '@/hooks/api/useFeedbackDataMutation';
import { AppContextType } from '@/context/context';
import { LectureFeedbackData } from '@/types/lectureFeedbackData';
import { StyledButton } from '@/components/StyledButton';
import { useCheckCorrectFormStep } from '@/hooks/useCheckCorrectFormStep';
import { FeelingCaption } from '@/components/FeelingCaption';
import { SelectedNeeds } from '@/components/SelectedNeeds';

const lectureFeedbackDataForApi = (
  lectureId: string,
  contextData: AppContextType['feedbackData']
): LectureFeedbackData => ({
  moodIntensity: null,
  lectureId,
  feelingIds: contextData.feelingIds,
  needIds: contextData.needIds,
  positiveComment: contextData.comments.positive.text,
  negativeComment: contextData.comments.negative.text,
  feelingsToNeeds: contextData.feelingsToNeeds,
});

export const FeedbackFormPage4Page = () => {
  const linkCode = useLinkCodeParam();

  const { isLoading, error, data } = useLectureByLinkCode(linkCode);

  useCheckCorrectFormStep(isLoading, data?.data);

  const navigate = useNavigate();

  const { feedbackData, comments, selectedFeelings, selectedNeeds, feelingsToNeeds } = useFeedbackDataContext();

  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  const feedbackExists = isFeedbackSubmitted || data?.data.isExistingFeedback;

  const mutation = useFeedbackDataMutation();

  const needsById = useContext(AppContext).appContext.needs.needsById;
  const feelingsById = useContext(AppContext).appContext.feelings.feelingsById;

  if (error) {
    return <Typography>Lekce nenalezena</Typography>;
  }

  if (isLoading || !data) {
    return <FullscreenSpinner />;
  }

  const lecture = data.data;

  const isPositiveComment = !!comments.positive.text && comments.positive.checked;
  const isNegativeComment = !!comments.negative.text && comments.negative.checked;

  const onDataSubmit = () => {
    if (!mutation.isLoading) {
      mutation.mutate(lectureFeedbackDataForApi(lecture.id, feedbackData), {
        onSuccess: () => {
          setIsFeedbackSubmitted(true);
        },
      });
    }
  };

  return (
    <>
      <FeedbackFormHeader lectureName={lecture.name} activeStep={3} hideStepper={!!feedbackExists} />

      {feedbackExists ? (
        <Box mb={2}>
          <Alert severity="success">Děkujeme za odeslasný feedback.</Alert>
        </Box>
      ) : (
        <>
          <Box mb={2}>
            <Typography style={{ fontSize: 18 }}>
              <b>Zvolené pocity</b>
            </Typography>
          </Box>

          <Box mb={3}>
            <SelectedEmotions items={selectedFeelings} />
          </Box>

          {selectedNeeds.fulfilled.length > 0 && (
            <>
              <Box mb={2}>
                <Typography style={{ fontSize: 18 }}>
                  <b>Zvolené naplněné potřeby</b>
                </Typography>
              </Box>

              <Box mb={3}>
                {selectedNeeds.fulfilled.map((needId, i) => {
                  const need = needsById[needId];

                  return (
                    <Box mb={2} key={i}>
                      <IconButtonBox title={need.name} content={need.subNeeds.join(', ')} icon={need.icon} />
                    </Box>
                  );
                })}
              </Box>
            </>
          )}

          {selectedNeeds.unfulfilled.length > 0 && (
            <>
              <Box mb={2}>
                <Typography style={{ fontSize: 18 }}>
                  <b>Zvolené nenaplněné potřeby</b>
                </Typography>
              </Box>

              <Box mb={3}>
                {selectedNeeds.unfulfilled.map((needId, i) => {
                  const need = needsById[needId];

                  return (
                    <Box mb={2} key={i}>
                      <IconButtonBox title={need.name} content={need.subNeeds.join(', ')} icon={need.icon} />
                    </Box>
                  );
                })}
              </Box>
            </>
          )}

          {feelingsToNeeds.length > 0 && (
            <Box mb={2}>
              <Box mb={2}>
                <Typography style={{ fontSize: 18 }}>
                  <b>Přiřazené pocity k potřebám</b>
                </Typography>
              </Box>

              {feelingsToNeeds
                .filter((feelingToNeeds) => feelingToNeeds.needIds.length > 0)
                .map((feelingToNeeds) => (
                  <div key={feelingToNeeds.feelingId}>
                    <Box mb={2}>
                      <FeelingCaption feeling={feelingsById[feelingToNeeds.feelingId]} />
                    </Box>

                    <SelectedNeeds caption={''} items={feelingToNeeds.needIds} />
                  </div>
                ))}
            </Box>
          )}

          {(isPositiveComment || isNegativeComment) && (
            <Box mb={2}>
              <Typography style={{ fontSize: 18 }}>
                <b>Vyplněný feedback</b>
              </Typography>
            </Box>
          )}

          {isPositiveComment && (
            <Box mb={3}>
              <Typography>{comments.positive.text}</Typography>
            </Box>
          )}

          {isNegativeComment && (
            <Box mb={3}>
              <Typography>{comments.negative.text}</Typography>
            </Box>
          )}

          {!feedbackExists && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(routes.feedbackFormPage3(linkCode));
                  }}
                >
                  Zpět
                </Button>
                <StyledButton onClick={onDataSubmit} isSubmitting={mutation.isLoading}>
                  Odeslat
                </StyledButton>
                {/*<Button variant="contained" onClick={onDataSubmit}>*/}
                {/*  <CircularProgress size={24} style={{ color: '#fff' }} />*/}
                {/*</Button>*/}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
