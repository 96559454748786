import * as React from 'react';
import { useContext, useState } from 'react';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { IconButtonBox } from '@/components/IconButtonBox';
import { TabPanel } from '@/components/TabPanel';
import { SelectedNeeds } from '@/components/SelectedNeeds';
import { routes } from '@/routes';
import { useNavigate } from 'react-router-dom';
import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { Need, NeedType } from '@/types/need';
import { FeedbackFormHeader } from '@/components/FeedbackFormHeader';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useLectureByLinkCode } from '@/hooks/api/useLectureByLinkCode';
import { FullscreenSpinner } from '@/components/FullscreenSpinner';
import { AppContext } from '@/context/AppContext';
import { MessageDialog } from '@/components/MessageDialog';
import { useCheckCorrectFormStep } from '@/hooks/useCheckCorrectFormStep';
import { feelingType } from '@/utils/feeling';

const getNeedsErrorMessage = (shouldFillInFulfilledNeed: boolean, shouldUnfillInFulfilledNeed: boolean) => {
  if (shouldFillInFulfilledNeed && shouldUnfillInFulfilledNeed) {
    return 'Vyplňte prosím alespoň jednu naplněnou a nenaplněnou potřebu.';
  } else if (shouldFillInFulfilledNeed) {
    return 'Vyplňte prosím alespoň jednu naplněnou potřebu.';
  } else if (shouldUnfillInFulfilledNeed) {
    return 'Vyplňte prosím alespoň jednu nenaplněnou potřebu.';
  }

  return '';
};

export const FeedbackFormPage2Page = () => {
  useCheckCorrectFormStep();

  const [activeTab, setActiveTab] = useState(0);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const { updateNeeds, selectedNeeds, selectedFeelings } = useFeedbackDataContext();

  const appContext = useContext(AppContext);

  const feelingsById = appContext.appContext.feelings.feelingsById;

  const linkCode = useLinkCodeParam();

  const navigate = useNavigate();

  const { isLoading, error, data } = useLectureByLinkCode(linkCode);

  const needs = useContext(AppContext).appContext.needs.needs;

  const onNeedClick = (need: Need) => {
    const type = activeTab === 0 ? 'fulfilled' : 'unfulfilled';

    updateNeeds(need.id, type);
  };

  const onNeedDelete = (type: NeedType) => ({
    type,
    onDelete: (needId: string, type: NeedType) => updateNeeds(needId, type),
  });

  if (error) {
    return <Typography>Lekce nenalezena</Typography>;
  }

  if (isLoading || !data) {
    return <FullscreenSpinner />;
  }

  const lecture = data.data;

  const pleasantFeelingsSelected =
    selectedFeelings.filter((feelingId) => feelingType(feelingsById[feelingId]) === 'pleasant').length > 0;
  const unpleasantFeelingsSelected =
    selectedFeelings.filter((feelingId) => feelingType(feelingsById[feelingId]) === 'unpleasant').length > 0;

  const onNextStepClick = () => {
    if (
      (!pleasantFeelingsSelected || selectedNeeds.fulfilled.length > 0) &&
      (!unpleasantFeelingsSelected || selectedNeeds.unfulfilled.length > 0)
    ) {
      if (
        selectedFeelings.length === 0 ||
        (selectedNeeds.unfulfilled.length === 0 && selectedNeeds.fulfilled.length === 0)
      ) {
        navigate(routes.feedbackFormPage3(linkCode));
      } else {
        navigate(routes.feedbackFormPage2b(linkCode));
      }
    } else {
      setErrorDialogOpen(true);
    }
  };

  const shouldFillInFulfilledNeed = pleasantFeelingsSelected && selectedNeeds.fulfilled.length === 0;
  const shouldUnfillInFulfilledNeed = unpleasantFeelingsSelected && selectedNeeds.unfulfilled.length === 0;

  return (
    <>
      <MessageDialog
        message={getNeedsErrorMessage(shouldFillInFulfilledNeed, shouldUnfillInFulfilledNeed)}
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
        }}
      />

      <FeedbackFormHeader lectureName={lecture.name} activeStep={1} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={(_, value) => {
            setActiveTab(value);
          }}
          aria-label="basic tabs example"
        >
          <Tab label="Naplněné potřeby" />
          <Tab label="Nenaplněné potřeby" />
        </Tabs>
      </Box>
      {/*<Box mb={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>*/}

      {/*<Box mr={2}>*/}
      {/*  <BorderPieChart size={65} color={'rgba(71, 88, 248, 0.2)'} fillColor={theme.palette.primary.main} text={'3 z 6'} progress={48} />*/}
      {/*</Box>*/}

      {/*<Box mb={2}>*/}
      {/*  <Typography variant="h6" component="div" sx={{flexGrow: 1}} color={'primary'}>*/}
      {/*     Naplněné potřeby*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
      {/*</Box>*/}

      <TabPanel value={activeTab} index={0}>
        {/*  <TableContainer component={Paper}>*/}
        {/*    <Table sx={{minWidth: 650}} aria-label="simple table">*/}
        {/*      <TableBody>*/}
        {/*        {needs.slice(6, 16).map((need, i) => (*/}
        {/*          <StyledTableRow*/}
        {/*            hover*/}
        {/*            key={i}*/}
        {/*            sx={{'&:last-child td, &:last-child th': {border: 0}}}*/}
        {/*          >*/}
        {/*            <TableCell component="th" scope="row" style={{ paddingTop: 50, paddingBottom: 50 }}>*/}
        {/*              <div style={{ display: 'flex', alignItems: 'center', fontSize: 36, fontWeight: 800 }}>*/}
        {/*                <Box mr={1} ml={4} style={{ marginRight: 64 }}>{need.icon}</Box> <Typography style={{ lineHeight: 1, fontSize: 80 }}>{need.title}</Typography>*/}
        {/*              </div>*/}
        {/*            </TableCell>*/}
        {/*          </StyledTableRow>*/}
        {/*        ))}*/}
        {/*      </TableBody>*/}
        {/*    </Table>*/}
        {/*  </TableContainer>*/}

        {needs.map((need, i) => (
          <Box mb={3} key={need.id}>
            <IconButtonBox
              title={need.name}
              content={need.subNeeds.join(', ')}
              icon={need.icon}
              onClick={() => {
                onNeedClick(need);
              }}
              selected={selectedNeeds['fulfilled'].indexOf(need.id) > -1}
            />
          </Box>
        ))}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        {needs.map((need, i) => (
          <Box mb={2} key={need.id}>
            <IconButtonBox
              title={need.name}
              content={need.subNeeds.join(', ')}
              icon={need.icon}
              onClick={() => {
                onNeedClick(need);
              }}
              selected={selectedNeeds['unfulfilled'].indexOf(need.id) > -1}
            />
          </Box>
        ))}
      </TabPanel>

      <SelectedNeeds
        caption={'Zvolené naplněné potřeby'}
        items={selectedNeeds['fulfilled']}
        deleteAction={onNeedDelete('fulfilled')}
      />

      <SelectedNeeds
        caption={'Zvolené nenaplněné potřeby'}
        items={selectedNeeds['unfulfilled']}
        deleteAction={onNeedDelete('unfulfilled')}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate(routes.feedbackFormPage1(linkCode));
          }}
        >
          Zpět
        </Button>
        <Button variant="contained" onClick={onNextStepClick}>
          Pokračovat
        </Button>
      </div>
    </>
  );
};
