import * as React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { BarChart } from '../../components/BarChart';
import { barChartMixedNeedsData } from '../../utils/fixtures';
import { TextRatingTable } from '../admin-statistics/components/TextRatingTable';
import { StatisticsCard } from '../../components/StatisticsCard';
import { LineChart } from '../../components/LineChart';
import { faker } from '@faker-js/faker';
import { months } from '../../utils/data';

export const StatisticsFeelingDetailPage = () => {
  const feelingName = 'Rovnováha';

  return (
    <>
      <div style={{ margin: 'auto', width: 1600 }}>
        <Paper elevation={3}>
          <Box p={3}>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} mb={3}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    background: 'rgba(45,227,136,0.5)',
                    borderRadius: 50,
                    marginRight: 12,
                  }}
                />
                <b>{feelingName}</b>
              </div>
            </Typography>

            <Box style={{ display: 'flex', gap: 20, flex: '1 1 0px' }} mb={3}>
              <StatisticsCard title={'Celkový počet'} content={'120'} />
              <StatisticsCard title={'Procentuální zastoupení'} content={'30%'} />
              <StatisticsCard title={'Nárůst za poslední týden'} content={'50'} />
              <StatisticsCard title={'Nárůsta za poslední měsíc'} content={'240'} />
            </Box>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={5}>
              <b>Vývoj typů pocitu v čase</b>
            </Typography>

            <LineChart
              data={{
                labels: months,
                datasets: [
                  {
                    label: feelingName,
                    data: months.map(() => faker.datatype.number({ min: 0, max: 100 })),
                    borderColor: 'rgba(255,229,45, 0.5)',
                    backgroundColor: 'rgba(255,229,45, 0.5)',
                  },
                ],
              }}
            />

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
              <b>Přiřazené potřeby</b>
            </Typography>

            <Box mb={2}>
              <BarChart data={barChartMixedNeedsData} options={{ min: -100, max: 100 }} />
            </Box>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
              <b>Feedback spojený s potřebami</b>
            </Typography>

            <TextRatingTable />
          </Box>
        </Paper>
      </div>
    </>
  );
};
