import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Box, Button, Slider, Typography } from '@mui/material';
import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@/routes';
import { FeedbackFormHeader } from '@/components/FeedbackFormHeader';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useLectureByLinkCode } from '@/hooks/api/useLectureByLinkCode';
import { FullscreenSpinner } from '@/components/FullscreenSpinner';
import { AppContext } from '@/context/AppContext';
import { ButtonBox } from '@/components/ButtonBox';

export const FeedbackFormV2Page2Page = () => {
  const [, set] = useState();

  const [type, setType] = useState<string | null>(null);

  const [allFeelingsShown, setAllFeelingsShown] = useState<boolean>(false);

  const { selectedFeelings, updateFeelings, moodIntensity } = useFeedbackDataContext();

  const { appContext } = useContext(AppContext);

  const emotions = appContext.feelings.allFeelings.filter(
    (feeling) => moodIntensity >= (feeling?.moodIntensityMin || 0) && moodIntensity <= (feeling?.moodIntensityMax || 0)
  );

  const linkCode = useLinkCodeParam();

  const navigate = useNavigate();

  const { isLoading, error, data } = useLectureByLinkCode(linkCode);

  if (error) {
    return <Typography>Lekce nenalezena</Typography>;
  }

  if (isLoading || !data) {
    return <FullscreenSpinner />;
  }

  const lecture = data.data;

  const onNextStepClick = () => {
    navigate(routes.feedbackFormV2Page3(linkCode));
  };

  return (
    <>
      <FeedbackFormHeader lectureName={lecture.name} activeStep={1} hideStepper />

      <Box mb={2}>
        <Typography>Vyber konkrétní pocit</Typography>
      </Box>

      {emotions.slice(0, allFeelingsShown ? undefined : 4).map((emotion, i) => (
        <Box mb={2}>
          <ButtonBox
            title={emotion.name}
            selected={selectedFeelings.indexOf(emotion.id) > -1}
            onClick={() => {
              updateFeelings(emotion.id);
            }}
          />
        </Box>
      ))}

      <Box mb={3} textAlign={'center'}>
        <Button
          variant={'outlined'}
          onClick={() => {
            setAllFeelingsShown((allFeelingsShown) => !allFeelingsShown);
          }}
        >
          {allFeelingsShown ? 'Zobrazit méně pocitů' : 'Zobrazit všechny pocity'}
        </Button>
      </Box>

      {/*<Box mb={3} textAlign={'center'}>*/}
      {/*  <Button*/}
      {/*    variant={'outlined'}*/}
      {/*    onClick={() => {*/}
      {/*      setAllFeelingsShown((allFeelingsShown) => !allFeelingsShown);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Přidat vlastní pocit*/}
      {/*  </Button>*/}
      {/*</Box>*/}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate(routes.feedbackFormV2Page1(linkCode));
          }}
        >
          Zpět
        </Button>
        <Button variant="contained" onClick={onNextStepClick}>
          Poslední krok
        </Button>
      </div>
    </>
  );
};
