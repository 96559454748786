import React, { createElement, useEffect, useState } from 'react';

// import './App.css';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { HomepagePage } from './pages/homepage/HomepagePage';
import { FeedbackFormPage1Page } from './pages/feedback-form-page-1/FeedbackFormPage1Page';
import { AppBar, Box, Icon, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import { FeedbackFormPage2Page } from './pages/feedback-form-page-2/FeedbackFormPage2Page';
import { StatisticsPage } from './pages/admin-statistics/StatisticsPage';
import { LectureDetailPage } from './pages/admin-lecture-detail/LectureDetailPage';
import { SeminarsListPage } from './pages/admin-seminars-list/SeminarsListPage';
import { LecturesListPage } from './pages/admin-lectures-list/LecturesListPage';
import { FeedbackFormPage3Page } from './pages/feedback-form-page-3/FeedbackFormPage3Page';
import { FeedbackFormPage4Page } from './pages/feedback-form-page-4/FeedbackFormPage4Page';
import MenuIcon from '@mui/icons-material/Menu';
import { IntroPage } from './pages/intro/IntroPage';
import SchoolIcon from '@mui/icons-material/School';
import { FeedbackStatistics } from './pages/feedback-statistics/FeedbackStatistics';
import { APP_TITLE } from './constants';
import { StatisticsFeelingDetailPage } from './pages/admin-statistics-feeling-detail/StatisticsFeelingDetailPage';
import { StatisticsQuadrantDetailPage } from './pages/admin-statistics-quadrant-detail/StatisticsQuadrantDetailPage';
import { AppContext } from './context/AppContext';
import { AppContextType, defaultFeedbackDataValue } from './context/context';
import { routes } from './routes';
import { useScrollToTopOnRouteChange } from './hooks/useScrollToTopOnRouteChange';
import { useFeelings } from './hooks/api/useFeelings';
import { useNeeds } from './hooks/api/useNeeds';
import { FullscreenSpinner } from './components/FullscreenSpinner';
import { groupArray, indexArray } from './services/array';
import { Need } from './types/need';
import { needIcons } from '@/utils/data';
import { FeedbackFormPage2bPage } from '@/pages/feedback-form-page-2b/FeedbackFormPage2bPage';
import { FeedbackFormV2Page1Page } from '@/pages/feedback-form-v2-page-1/FeedbackFormV2Page1Page';
import { FeedbackFormV2Page2Page } from '@/pages/feedback-form-v2-page-2/FeedbackFormV2Page2Page';
import { FeedbackFormV2Page3Page } from './pages/feedback-form-v2-page-3/FeedbackFormV2Page3Page';

function App() {
  const theme = useTheme();

  const [appContext, setAppContext] = useState<AppContextType>(defaultFeedbackDataValue);

  useScrollToTopOnRouteChange();

  const appContextValue = {
    appContext,
    setAppContext,
  };

  const { pathname } = useLocation();

  const { isLoading: isFeelingsLoading, data: feelingsData } = useFeelings();
  const { isLoading: isNeedsLoading, data: needsData } = useNeeds();

  useEffect(() => {
    setAppContext((appContext) => ({
      ...appContext,
      feelings: {
        feelingsByType: groupArray(feelingsData?.data || [], (feeling) => feeling.type),
        feelingsById: indexArray(feelingsData?.data || [], (feeling) => feeling.id),
        allFeelings: feelingsData?.data || [],
      },
    }));
  }, [feelingsData]);

  useEffect(() => {
    const needs = (needsData?.data || []).map(
      (need): Need => ({
        id: need.id,
        icon: needIcons[need.icon],
        subNeeds: JSON.parse(need.description),
        name: need.name,
      })
    );

    setAppContext((appContext) => ({
      ...appContext,
      needs: {
        needs,
        needsById: indexArray(needs, (need) => need.id),
      },
    }));
  }, [needsData]);

  return (
    <AppContext.Provider value={appContextValue}>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            style={{
              background: pathname === '/' ? theme.palette.primary.main : '#fff',
              color: theme.palette.primary.main,
            }}
          >
            <Toolbar>
              <Box mr={1}>
                <SchoolIcon />
              </Box>
              <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                <Link to={'/'} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                  <b>{APP_TITLE}</b>
                </Link>
              </Typography>
              <IconButton size="large" edge="start" color={'default'} aria-label="menu">
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>

        {isFeelingsLoading && isNeedsLoading ? (
          <FullscreenSpinner />
        ) : (
          <Box p={3}>
            <Routes>
              <Route path="/homepage" element={<HomepagePage />} />
              <Route path={routes.introPage} element={<IntroPage />} />
              <Route path={routes.feedbackFormPage1(':linkCode')} element={<FeedbackFormPage1Page />} />
              <Route path={routes.feedbackFormPage2(':linkCode')} element={<FeedbackFormPage2Page />} />
              <Route path={routes.feedbackFormPage2b(':linkCode')} element={<FeedbackFormPage2bPage />} />
              <Route path={routes.feedbackFormPage3(':linkCode')} element={<FeedbackFormPage3Page />} />
              <Route path={routes.feedbackFormPage4(':linkCode')} element={<FeedbackFormPage4Page />} />
              <Route path={routes.feedbackFormV2Page1(':linkCode')} element={<FeedbackFormV2Page1Page />} />
              <Route path={routes.feedbackFormV2Page2(':linkCode')} element={<FeedbackFormV2Page2Page />} />
              <Route path={routes.feedbackFormV2Page3(':linkCode')} element={<FeedbackFormV2Page3Page />} />
              <Route path={routes.feedbackStatistics} element={<FeedbackStatistics />} />

              <Route path="admin-statistics" element={<StatisticsPage />} />
              <Route path="admin-statistics-feeling-detail" element={<StatisticsFeelingDetailPage />} />
              <Route path="admin-statistics-quadrant-detail" element={<StatisticsQuadrantDetailPage />} />
              <Route path="admin-lecture-detail" element={<LectureDetailPage />} />
              <Route path="admin-seminars-list" element={<SeminarsListPage />} />
              <Route path="admin-lectures-list" element={<LecturesListPage />} />
            </Routes>
          </Box>
        )}
      </div>
    </AppContext.Provider>
  );
}

export default App;
