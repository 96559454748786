import * as React from 'react';
import { emotionColorsAlternative } from '@/utils/data';
import { Typography } from '@mui/material';
import { Feeling } from '@/types/feeling';

interface Props {
  feeling: Feeling;
}

export const FeelingCaption = ({ feeling }: Props) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div
      style={{
        background: `rgb(${emotionColorsAlternative[feeling.type].bg})`,
        borderRadius: '50%',
        width: 30,
        height: 30,
        marginRight: 10,
      }}
    />{' '}
    <Typography>{feeling.name}</Typography>
  </div>
);
