import { Feeling } from '../types/feeling';
import { Need } from '../types/need';

interface FeedbackComment {
  checked: boolean;
  text: string;
}

export interface AppContextType {
  feedbackData: {
    moodIntensity: number;
    feelingIds: string[];
    needIds: {
      fulfilled: string[];
      unfulfilled: string[];
    };
    feelingsToNeeds: {
      feelingId: string;
      expanded: boolean;
      needIds: string[];
    }[];
    comments: {
      positive: FeedbackComment;
      negative: FeedbackComment;
    };
  };
  feelings: {
    feelingsByType: Record<string, Feeling[]>;
    feelingsById: Record<string, Feeling>;
    allFeelings: Feeling[];
  };
  needs: {
    needs: Need[];
    needsById: Record<string, Need>;
  };
}

export const defaultFeedbackDataValue = {
  feedbackData: {
    moodIntensity: 50,
    feelingIds: [],
    needIds: {
      fulfilled: [],
      unfulfilled: [],
    },
    feelingsToNeeds: [],
    comments: {
      positive: {
        checked: false,
        text: '',
      },
      negative: {
        checked: false,
        text: '',
      },
    },
  },
  feelings: {
    feelingsByType: {},
    feelingsById: {},
    allFeelings: [],
  },
  needs: {
    needs: [],
    needsById: {},
  },
};

export const appContextValue = {
  appContext: {
    ...defaultFeedbackDataValue,
  },
  setAppContext: (updateCallback: (currentAppContext: AppContextType) => AppContextType) => {},
};
