import {feelingColor, feelingEmoji, feelingText} from "@/utils/feeling";
import {Typography} from "@mui/material";
import * as React from "react";

interface Props {
    moodIntensity: number;
}

export const MoodBox = ({ moodIntensity }: Props) => (
    <div style={{ height: 300, borderRadius: 20, backgroundColor: feelingColor(moodIntensity), padding: 20, marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center' }}>
            <img src={`/emojis/${feelingEmoji(moodIntensity)}.png`} width="100" height="100" alt="" style={{ margin: 'auto', display: 'block', marginBottom: 20 }} />
            <Typography fontSize={20}>{feelingText(moodIntensity)}</Typography>
        </div>
    </div>);


