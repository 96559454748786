import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, Slider, Typography } from '@mui/material';
import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@/routes';
import { FeedbackFormHeader } from '@/components/FeedbackFormHeader';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useLectureByLinkCode } from '@/hooks/api/useLectureByLinkCode';
import { FullscreenSpinner } from '@/components/FullscreenSpinner';
import { AppContext } from '@/context/AppContext';
import { useFeedbackDataMutation } from '@/hooks/api/useFeedbackDataMutation';
import { AppContextType } from '@/context/context';
import { LectureFeedbackData } from '@/types/lectureFeedbackData';
import { MoodBox } from '@/components/MoodBox';
import { SelectedEmotions } from '@/components/SelectedEmotions';

const lectureFeedbackDataForApi = (
  lectureId: string,
  contextData: AppContextType['feedbackData']
): LectureFeedbackData => ({
  lectureId,
  moodIntensity: contextData.moodIntensity,
  feelingIds: contextData.feelingIds,
  needIds: {
    fulfilled: [],
    unfulfilled: [],
  },
  positiveComment: '',
  negativeComment: '',
  feelingsToNeeds: [
    {
      feelingId: '',
      needIds: [],
    },
  ],
});

export const FeedbackFormV2Page3Page = () => {
  const [, set] = useState();

  const { feedbackData, selectedFeelings, updateFeelings, moodIntensity } = useFeedbackDataContext();

  const linkCode = useLinkCodeParam();

  const navigate = useNavigate();

  const { isLoading, error, data } = useLectureByLinkCode(linkCode);

  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  const mutation = useFeedbackDataMutation();

  const feedbackExists = isFeedbackSubmitted; // || data?.data.isExistingFeedback;

  if (error) {
    return <Typography>Lekce nenalezena</Typography>;
  }

  if (isLoading || !data) {
    return <FullscreenSpinner />;
  }

  const lecture = data.data;

  const onNextStepClick = () => {
    navigate(routes.feedbackFormV2Page3(linkCode));
  };

  const onDataSubmit = () => {
    if (!mutation.isLoading) {
      mutation.mutate(lectureFeedbackDataForApi(lecture.id, feedbackData), {
        onSuccess: () => {
          setIsFeedbackSubmitted(true);
        },
      });
    }
  };

  return (
    <>
      {feedbackExists ? (
        <Box mb={2}>
          <Alert severity="success">Děkujeme za odeslasný feedback.</Alert>
        </Box>
      ) : (
        <>
          <FeedbackFormHeader lectureName={lecture.name} activeStep={1} hideStepper />

          <Box mb={2}>
            <MoodBox moodIntensity={moodIntensity} />
          </Box>

          <Box mb={3}>
            <SelectedEmotions items={selectedFeelings} />
          </Box>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(routes.feedbackFormV2Page2(linkCode));
              }}
            >
              Zpět
            </Button>
            <Button variant="contained" onClick={onDataSubmit}>
              Odeslat
            </Button>
          </div>
        </>
      )}
    </>
  );
};
