import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { FeedbackFormStepper } from './FeedbackFormStepper';

interface Props {
  lectureName: string;
  activeStep: number;
  hideStepper?: boolean;
}

export const FeedbackFormHeader = ({ lectureName, activeStep, hideStepper }: Props) => (
  <>
    <Box mb={3}>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <b>{lectureName}</b>
      </Typography>
    </Box>

    {/*<Box mb={3}>*/}
    {/*  <Typography>Lektor: Jan Novák</Typography>*/}
    {/*</Box>*/}

    {!hideStepper && (
      <FeedbackFormStepper activeStep={activeStep} />
    )}

    {/*<Box mb={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>*/}
    {/*  /!*<Box mr={2}>*!/*/}
    {/*  /!*  <BorderPieChart size={65} color={'rgba(71, 88, 248, 0.2)'} fillColor={theme.palette.primary.main} text={'6 z 6'} progress={100} />*!/*/}
    {/*  /!*</Box>*!/*/}

    {/*  <Typography variant="h6" component="div" sx={{flexGrow: 1}} color={'primary'}>*/}
    {/*     Shrnutí*/}
    {/*  </Typography>*/}
    {/*</Box>*/}
  </>
);
