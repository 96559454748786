import * as React from 'react';
import { emotionColorsAlternative, emotions } from '../utils/data';
import { Typography } from '@mui/material';
import { Mood, MoodBad, SentimentSatisfiedAlt, SentimentVeryDissatisfied } from '@mui/icons-material';
import { Feeling } from '../types/feeling';

interface Props {
  fixedColors?: boolean;
  includeCounts?: boolean;
}

const chunkArray = (arr: any, chunkSize: any) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

interface FeelingsTableProps {
  type: string;
  fixedColors?: boolean;
  includeCounts?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const feelingsContent = {
  intenseUnpleasant: {
    title: 'Vysoká intenzita',
    type: 'Nepříjemné',
    icon: <MoodBad fontSize={'large'} style={{ transform: 'scale(8)' }} />,
    img: 'very-sad',
  },
  intensePleasant: {
    title: 'Vysoká intenzita',
    type: 'Příjemné',
    icon: <Mood fontSize={'large'} style={{ transform: 'scale(8)' }} />,
    img: 'very-happy',
  },
  notIntenseUnpleasant: {
    title: 'Nízká intenzita',
    type: 'Nepříjemné',
    icon: <SentimentVeryDissatisfied fontSize={'large'} style={{ transform: 'scale(8)' }} />,
    img: 'sad',
  },
  notIntensePleasant: {
    title: 'Nízká intenzita',
    type: 'Příjemné',
    icon: <SentimentSatisfiedAlt fontSize={'large'} style={{ transform: 'scale(8)' }} />,
    img: 'happy',
  },
};

const feelingsMaxCount = 8;

const feelingCounts: Record<string, number> = {
  // odhodlání
  '25': 5,
  // podráždění
  '12': 3,
  // naštvání
  '14': 5,
  // dobrá nálada
  '37': 8,
  // radost
  '38': 5,
  // hravost
  '40': 3,
  // motivace
  '27': 4,
  // zvědavost
  '26': 2,
  // hravost
  '31': 1,
  // nadšení
  '36': 4,
  // ospalost
  '52': 7,
  // spokojenost
  '61': 6,
  // pohoda
  '62': 4,
  // důvěra
  '64': 5,
  // vděčnost
  '67': 2,
  // naplnění
  '71': 3,
  // bezpečí
  '77': 7,
  // vyrovnanost
  '80': 6,
  // vyrovnanost
  '74': 4,
};

const FeelingsTable = ({ type, fixedColors, includeCounts }: FeelingsTableProps) => {
  const colorsData = emotionColorsAlternative; //fixedColors ? emotionColorsAlternative : emotionColors;

  // big emoji version
  // return <div style={{
  //   background: `rgb(${colorsData[type].bg})`,
  //   width: 960,
  //   height: 525,
  //   textAlign: 'center',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // }}>
  //   <Typography style={{ fontSize: 48, textTransform: 'uppercase', fontWeight: 800, }}>
  //     {/*// @ts-ignore*/}
  //     <img src={`/emojis/${feelingsContent[type].img}.png`} alt="" style={{ width: 350 }} />
  //   </Typography>
  // </div>;

  return (
    <table style={{ borderSpacing: 0 }} cellSpacing="0" cellPadding="0">
      {chunkArray(emotions[type], 4).map((chunk, y) => {
        return (
          <tr key={y}>
            {/*<div style={{ display: 'flex', flex: '1 1 0px' }} key={i}>*/}
            {chunk.map((emotion: Feeling, x: number) => {
              // const frequency = Math.random();
              const feelingsCount = feelingCounts[emotion.id] ? feelingCounts[emotion.id] : 0;
              const frequency = feelingsCount / feelingsMaxCount;

              const baseFeelingColor = frequency > 0 ? colorsData[type].bg : '250, 250, 250';

              const currentColor = `rgba(${baseFeelingColor}, ${fixedColors || frequency === 0 ? 1 : frequency})`;

              return (
                <td
                  key={x}
                  style={{
                    verticalAlign: 'top',
                    // width: '25%',
                    // height: 40,
                    // border: `1px solid ${selected ? emotionColors[type].text : 'transparent'}`,
                    background: currentColor,
                    padding: `${
                      includeCounts && emotion.name !== 'Bezstarostnost'
                        ? 12
                        : emotion.name !== 'Bezstarostnost'
                        ? 32
                        : 12
                    }px ${emotion.name !== 'Dobrá nálada' ? 8 : 0}px`,
                    color: '#fff',
                    border: '1px solid #fff',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '100%',
                      width: emotion.name !== 'Dobrá nálada' ? 222 : undefined,
                      // padding: '0 30px',
                    }}
                  >
                    <Typography
                      style={{
                        color: '#000',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        fontWeight: 800,
                        fontSize: 26,
                      }}
                    >
                      {emotion.name !== 'Bezstarostnost' ? (
                        emotion.name !== 'Dobrá nálada' ? (
                          emotion.name
                        ) : (
                          <>Dobrá&nbsp;nálada</>
                        )
                      ) : (
                        <>
                          BEZ-
                          <br />
                          STARNOSTNOST
                        </>
                      )}

                      {includeCounts && (
                        <span style={{ fontSize: '0.8em' }}>
                          <br />
                          {feelingsCount > 0 ? feelingsCount : emotion.name !== 'Bezstarostnost' ? <>&nbsp;</> : <></>}
                        </span>
                      )}
                    </Typography>
                  </div>
                </td>
              );
            })}
            {/*</div>*/}
          </tr>
        );
      })}
    </table>
  );
};

// 672 x 370
// 960 x 525

export const HeatMapChart = ({ fixedColors, includeCounts }: Props) => {
  return (
    <>
      <div style={{ display: 'flex', borderBottom: '2px solid #000' }}>
        <div style={{ paddingRight: 16, padding: 16, paddingLeft: 0, borderRight: '2px solid #000' }}>
          <FeelingsTable type={'intenseUnpleasant'} fixedColors={fixedColors} includeCounts={includeCounts} />
        </div>
        <div style={{ marginLeft: 16, paddingTop: 16 }}>
          <FeelingsTable type={'intensePleasant'} fixedColors={fixedColors} includeCounts={includeCounts} />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingRight: 16, padding: 16, paddingLeft: 0, borderRight: '2px solid #000' }}>
          <FeelingsTable type={'notIntenseUnpleasant'} fixedColors={fixedColors} includeCounts={includeCounts} />
        </div>
        <div style={{ marginLeft: 16, paddingTop: 16 }}>
          <FeelingsTable type={'notIntensePleasant'} fixedColors={fixedColors} includeCounts={includeCounts} />
        </div>
      </div>
    </>
  );
};
