import * as React from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { routes } from '@/routes';
import { useNavigate } from 'react-router-dom';
import { FeedbackFormHeader } from '@/components/FeedbackFormHeader';
import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useLectureByLinkCode } from '@/hooks/api/useLectureByLinkCode';
import { FullscreenSpinner } from '@/components/FullscreenSpinner';
import { useCheckCorrectFormStep } from '@/hooks/useCheckCorrectFormStep';

export const FeedbackFormPage3Page = () => {
  useCheckCorrectFormStep();

  const { comments, updateCommentText, toggleCommentType, selectedFeelings, selectedNeeds } = useFeedbackDataContext();

  const linkCode = useLinkCodeParam();

  const navigate = useNavigate();

  const { isLoading, error, data } = useLectureByLinkCode(linkCode);

  if (error) {
    return <Typography>Lekce nenalezena</Typography>;
  }

  if (isLoading || !data) {
    return <FullscreenSpinner />;
  }

  const lecture = data.data;

  return (
    <>
      <FeedbackFormHeader lectureName={lecture.name} activeStep={2} />

      <Box mb={2}>
        <FormGroup>
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={comments.positive.checked}
                  onChange={() => {
                    toggleCommentType('positive');
                  }}
                />
              }
              label="Příště bych potřeboval/a něco stejně"
            />
          </Box>

          {comments.positive.checked && (
            <Box mb={3}>
              <TextField
                id="outlined-multiline-static"
                label="Naplněné potřeby"
                multiline
                value={comments.positive.text}
                rows={4}
                fullWidth
                onChange={(e) => {
                  updateCommentText(e.target.value, 'positive');
                }}
              />
            </Box>
          )}

          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={comments.negative.checked}
                  onChange={() => {
                    toggleCommentType('negative');
                  }}
                />
              }
              label="Příště bych potřeboval/a něco jinak"
            />
          </Box>

          {comments.negative.checked && (
            <Box mb={3}>
              <TextField
                id="outlined-multiline-static"
                label="Nenaplněné potřeby"
                multiline
                value={comments.negative.text}
                rows={4}
                fullWidth
                onChange={(e) => {
                  updateCommentText(e.target.value, 'negative');
                }}
              />
            </Box>
          )}
        </FormGroup>
      </Box>

      {/*<Box mb={2}>*/}
      {/*  <Typography>Co byste doplnili k naplněným potřebám?</Typography>*/}
      {/*</Box>*/}

      {/*<Box mb={2}>*/}
      {/*  <Typography>Co byste doplnili k nenaplněným potřebám?</Typography>*/}
      {/*</Box>*/}

      {/*<Box mb={3}>*/}
      {/*  <TextField*/}
      {/*    id="outlined-multiline-static"*/}
      {/*    label="Nenaplněné potřeby"*/}
      {/*    multiline*/}
      {/*    rows={4}*/}
      {/*    fullWidth*/}
      {/*  />*/}
      {/*</Box>*/}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate(routes.feedbackFormPage2b(linkCode));
          }}
        >
          Zpět
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            navigate(routes.feedbackFormPage4(linkCode));
          }}
        >
          Pokračovat
        </Button>
      </div>
    </>
  );
};
