import * as React from 'react';
import { Box, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { CodeTextField } from '../../components/CodeTextField';
import SchoolIcon from '@mui/icons-material/School';
import { Link, useNavigate } from 'react-router-dom';
import { APP_TITLE } from '../../constants';
import { useLectureAccessCode } from '../../hooks/api/useLectureByAccessCode';
import { useEffect, useState } from 'react';
import { routes } from '../../routes';

export const IntroPage = () => {
  const [accessCode, setAccessCode] = useState<string>('');

  const navigate = useNavigate();

  const { isLoading, error, refetch } = useLectureAccessCode(accessCode, (data) => {
    navigate(routes.feedbackFormPage1(data.data.linkCode));
  });

  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = () => {
    if (!accessCode) {
      setErrorMessage('Zadejte správný kód');
      return;
    }

    refetch();
  };

  useEffect(() => {
    if (!!error) {
      setErrorMessage('Zadejte správný kód');
    }
  }, [error]);

  return (
    <>
      <Dialog
        onClose={() => {
          setErrorMessage('');
        }}
        open={!!errorMessage}
      >
        <DialogTitle>{errorMessage}</DialogTitle>
      </Dialog>

      <div className="bg">
        <div style={{ height: '100vh' }}>
          <Box p={1} pl={3} mb={18} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ color: '#fff' }} mr={1}>
                  <SchoolIcon />
                </Box>
                <Link to={'/'} style={{ textDecoration: 'none' }}>
                  <Typography style={{ fontSize: 24 }}>
                    <span style={{ color: '#fff' }}>{APP_TITLE}</span>
                  </Typography>
                </Link>
              </Box>
            </Box>
            {/*<Typography style={{ fontSize: 24 }}><span style={{ color: '#fff' }}>Academic Feedback</span></Typography>*/}
            <IconButton size="large" edge="start" aria-label="menu">
              <MenuIcon style={{ color: '#fff' }} />
            </IconButton>
          </Box>

          <Box style={{ margin: 'auto', textAlign: 'center', width: '80%' }}>
            <Box mb={5}>
              <Typography>
                <span style={{ color: '#fff', fontSize: 20 }}>
                  Pro vstup do lekce zadejte kód nebo naskenujte QR kód fotoaparátem
                </span>
              </Typography>
            </Box>

            <CodeTextField value={accessCode} isLoading={isLoading} onChange={setAccessCode} onSubmit={onSubmit} />
          </Box>
        </div>
      </div>
    </>
  );
};
