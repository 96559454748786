import * as React from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';

interface Props {
  activeStep: number;
}

const steps = ['Pocity', 'Potřeby', 'Feedback', 'Shrnutí'];

export const FeedbackFormStepper = ({ activeStep }: Props) => (
  <Box mb={2}>
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  </Box>
);
