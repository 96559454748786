import { faker } from '@faker-js/faker';
import { months } from './data';

// export const bubbleChartData = {
//   datasets: [
//     {
//       label: 'Vysoká intenzita, pozitivní',
//       data: Array.from({ length: 40 }, () => ({
//         x: faker.datatype.number({ min: 0, max: 100 }),
//         y: faker.datatype.number({ min: 0, max: 100 }),
//         r: faker.datatype.number({ min: 5, max: 20 }),
//       })),
//       backgroundColor: 'rgba(255,229,45,0.5)',
//     },
//     {
//       label: 'Nízká intenzita, negativní',
//       data: Array.from({ length: 35 }, () => ({
//         x: faker.datatype.number({ min: -100, max: 0 }),
//         y: faker.datatype.number({ min: -100, max: 0 }),
//         r: faker.datatype.number({ min: 5, max: 20 }),
//       })),
//       backgroundColor: 'rgba(45,151,227,0.71)',
//     },
//     {
//       label: 'Vysoká intenzita, negativní',
//       data: Array.from({ length: 30 }, () => ({
//         x: faker.datatype.number({ min: -100, max: 0 }),
//         y: faker.datatype.number({ min: 50, max: 100 }),
//         r: faker.datatype.number({ min: 5, max: 20 }),
//       })),
//       backgroundColor: 'rgba(199,8,8,0.5)',
//     },
//     {
//       label: 'Nízká intenzita, pozitivní',
//       data: Array.from({ length: 50 }, () => ({
//         x: faker.datatype.number({ min: 0, max: 100 }),
//         y: faker.datatype.number({ min: -100, max: 0 }),
//         r: faker.datatype.number({ min: 5, max: 20 }),
//       })),
//       backgroundColor: 'rgba(45,227,136,0.5)',
//     },
//   ],
// };

export const bubbleDataset1 = (max: number) =>
  Array.from({ length: 40 }, (v, k) => ({
    label: `Pocit ${k + 1}`,
    data: [
      {
        x: faker.datatype.number({ min: 1, max: 4 }),
        y: faker.datatype.number({ min: 1, max: 5 }),
        r: faker.datatype.number({ min: 5, max }),
      },
    ],
    backgroundColor: 'rgba(255,229,45,0.5)',
  }));

export const bubbleDataset2 = (max: number) =>
  Array.from({ length: 35 }, (v, k) => ({
    label: `Pocit ${k + 1 + bubbleDataset1.length}`,
    data: [
      {
        x: faker.datatype.number({ min: -4, max: -1 }),
        y: faker.datatype.number({ min: -5, max: -1 }),
        r: faker.datatype.number({ min: 5, max }),
      },
    ],
    backgroundColor: 'rgba(45,151,227,0.71)',
  }));

export const bubbleDataset3 = (max: number) =>
  Array.from({ length: 30 }, (v, k) => ({
    label: `Pocit ${k + 1 + bubbleDataset1.length + bubbleDataset2.length}`,
    data: [
      {
        x: faker.datatype.number({ min: -4, max: -1 }),
        y: faker.datatype.number({ min: 1, max: 5 }),
        r: faker.datatype.number({ min: 5, max }),
      },
    ],
    backgroundColor: 'rgba(199,8,8,0.5)',
  }));

export const bubbleDataset4 = (max: number) =>
  Array.from({ length: 50 }, (v, k) => ({
    label: `Pocit ${k + 1 + bubbleDataset1.length + bubbleDataset2.length + bubbleDataset3.length}`,
    data: [
      {
        x: faker.datatype.number({ min: 1, max: 4 }),
        y: faker.datatype.number({ min: -5, max: -1 }),
        r: faker.datatype.number({ min: 5, max }),
      },
    ],
    backgroundColor: 'rgba(45,227,136,0.5)',
  }));

export const bubbleChartData = (max: number) => ({
  datasets: bubbleDataset1(max).concat(bubbleDataset2(max)).concat(bubbleDataset3(max)).concat(bubbleDataset4(max)),
});

export const lineChartData = {
  labels: months,
  datasets: [
    {
      label: 'Vysoká intenzita, pozitivní',
      data: months.map(() => faker.datatype.number({ min: 0, max: 100 })),
      borderColor: 'rgba(255,229,45, 0.5)',
      backgroundColor: 'rgba(255,229,45, 0.5)',
    },
    {
      label: 'Nízká intenzita, negativní',
      data: months.map(() => faker.datatype.number({ min: 0, max: 100 })),
      borderColor: 'rgba(45,151,227,0.71)',
      backgroundColor: 'rgba(45,151,227,0.71)',
    },
    {
      label: 'Vysoká intenzita, negativní',
      data: months.map(() => faker.datatype.number({ min: 0, max: 100 })),
      borderColor: 'rgba(199,8,8,0.5)',
      backgroundColor: 'rgba(199,8,8,0.5)',
    },
    {
      label: 'Nízká intenzita, pozitivní',
      data: months.map(() => faker.datatype.number({ min: 0, max: 100 })),
      borderColor: 'rgba(45,227,136,0.5)',
      backgroundColor: 'rgba(45,227,136,0.5)',
    },
  ],
};

export const pieChartFeelingsData = {
  labels: [
    'Vysoká intenzita, pozitivní',
    'Nízká intenzita, negativní',
    'Vysoká intenzita, negativní',
    'Nízká intenzita, pozitivní',
  ],
  datasets: [
    {
      label: '',
      data: [120, 50, 70, 150],
      backgroundColor: ['rgba(255,229,45, 0.5)', 'rgba(45,151,227,0.71)', 'rgba(199,8,8,0.5)', 'rgba(45,227,136,0.5)'],
      borderColor: ['rgba(255,229,45, 1)', 'rgba(45,151,227, 1)', 'rgba(199,8,8, 1)', 'rgba(45,227,136, 1)'],
      borderWidth: 1,
    },
  ],
};

export const pieChartFeelingsDataDetailed = {
  labels: [
    'Pocit 1',
    'Pocit 2',
    'Pocit 3',
    'Pocit 4',
    'Pocit 5',
    'Pocit 6',
    'Pocit 7',
    'Pocit 8',
    'Pocit 9',
    'Pocit 10',
  ],
  datasets: [
    {
      label: '',
      data: Array.from({ length: 10 }, () => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: [
        'rgba(147, 71, 163, 0.5)',
        'rgba(251, 229, 107, 0.5)',
        'rgba(20, 223, 26, 0.5)',
        'rgba(88, 99, 241, 0.5)',
        'rgba(195, 1, 11, 0.5)',
        'rgba(12, 81, 81, 0.5)',
        'rgba(209, 37, 237, 0.5)',
        'rgba(164, 70, 45, 0.5)',
        'rgba(247, 138, 11, 0.5)',
        'rgba(161, 11, 77, 0.5)',
      ],
      borderColor: [
        'rgba(147, 71, 163, 1)',
        'rgba(251, 229, 107, 1)',
        'rgba(20, 223, 26, 1)',
        'rgba(88, 99, 241, 1)',
        'rgba(195, 1, 11, 1)',
        'rgba(12, 81, 81, 1)',
        'rgba(209, 37, 237, 1)',
        'rgba(164, 70, 45, 1)',
        'rgba(247, 138, 11, 1)',
        'rgba(161, 11, 77, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const barChartPositiveData = {
  labels: [
    'Pocit 1',
    'Pocit 2',
    'Pocit 3',
    'Pocit 4',
    'Pocit 5',
    'Pocit 6',
    'Pocit 7',
    'Pocit 8',
    'Pocit 9',
    'Pocit 10',
  ],
  datasets: [
    {
      label: 'Pozitivní pocity',
      data: Array.from({ length: 10 }, (_, index) => (10 - index) * 10),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export const barChartNegativeData = {
  labels: [
    'Pocit 1',
    'Pocit 2',
    'Pocit 3',
    'Pocit 4',
    'Pocit 5',
    'Pocit 6',
    'Pocit 7',
    'Pocit 8',
    'Pocit 9',
    'Pocit 10',
  ],
  datasets: [
    {
      label: 'Negativní pocity',
      data: Array.from({ length: 10 }, (_, index) => (10 - index) * 10),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

const needs = [
  'Bezpečí a jistota',
  'Fyziologické potřeby',
  'Čestnost',
  'Autonomie',
  'Hravost',
  'Vnitřní klid',
  'Náklonnost',
];

const fullFilledNeeds = {
  label: 'Naplněné potřeby',
  data: needs.map(() => faker.datatype.number({ min: 0, max: 100 })),
  borderColor: 'rgb(53, 162, 235)',
  backgroundColor: 'rgba(53, 162, 235, 0.5)',
};

const unfullFilledNeeds = {
  label: 'Nenaplněné potřeby',
  data: needs.map(() => faker.datatype.number({ min: -100, max: 0 })),
  borderColor: 'rgb(255, 99, 132)',
  backgroundColor: 'rgba(255, 99, 132, 0.5)',
};

export const barChartMixedNeedsData = {
  labels: needs,
  datasets: [fullFilledNeeds, unfullFilledNeeds],
};

export const barChartFullFilleddNeedsData = {
  labels: needs,
  datasets: [{ ...fullFilledNeeds, data: Array.from({ length: 10 }, (_, index) => (10 - index) * 10) }],
};

export const barChartUnfullFilleddNeedsData = {
  labels: needs,
  datasets: [{ ...unfullFilledNeeds, data: Array.from({ length: 10 }, (_, index) => (10 - index) * 10) }],
};

export const pieChartNeedsData = () => ({
  labels: [
    'Potřeba 1',
    'Potřeba 2',
    'Potřeba 3',
    'Potřeba 4',
    'Potřeba 5',
    'Potřeba 6',
    'Potřeba 7',
    'Potřeba 8',
    'Potřeba 9',
    'Potřeba 10',
  ],
  datasets: [
    {
      label: '',
      data: Array.from({ length: 10 }, () => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: [
        'rgba(147, 71, 163, 0.5)',
        'rgba(251, 229, 107, 0.5)',
        'rgba(20, 223, 26, 0.5)',
        'rgba(88, 99, 241, 0.5)',
        'rgba(195, 1, 11, 0.5)',
        'rgba(12, 81, 81, 0.5)',
        'rgba(209, 37, 237, 0.5)',
        'rgba(164, 70, 45, 0.5)',
        'rgba(247, 138, 11, 0.5)',
        'rgba(161, 11, 77, 0.5)',
      ],
      borderColor: [
        'rgba(147, 71, 163, 1)',
        'rgba(251, 229, 107, 1)',
        'rgba(20, 223, 26, 1)',
        'rgba(88, 99, 241, 1)',
        'rgba(195, 1, 11, 1)',
        'rgba(12, 81, 81, 1)',
        'rgba(209, 37, 237, 1)',
        'rgba(164, 70, 45, 1)',
        'rgba(247, 138, 11, 1)',
        'rgba(161, 11, 77, 1)',
      ],
      borderWidth: 1,
    },
  ],
});
