import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@/routes';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useEffect } from 'react';
import { Lecture } from '@/types/lecture';

export const useCheckCorrectFormStep = (isLectureLoading?: boolean, lecture?: Lecture) => {
  const { selectedFeelings } = useFeedbackDataContext();
  const { selectedNeeds } = useFeedbackDataContext();

  const linkCode = useLinkCodeParam();

  const navigate = useNavigate();

  const location = useLocation();

  const isGreaterThanSecondStep = location.pathname.indexOf(routes.feedbackFormPage3('')) > -1;
  // location.pathname.indexOf(routes.feedbackFormPage3b('')) > -1;

  useEffect(() => {
    if (location.pathname.indexOf(routes.feedbackFormPage4('')) > -1) {
      if (!isLectureLoading && !!lecture) {
        if (
          !lecture.isExistingFeedback &&
          (selectedFeelings.length === 0 ||
            (selectedNeeds.fulfilled.length === 0 && selectedNeeds.unfulfilled.length === 0))
        ) {
          navigate(routes.feedbackFormPage1(linkCode));
        } else {
          return;
        }
      }
    }

    if (
      (selectedFeelings.length === 0 && isLectureLoading === undefined) ||
      (isGreaterThanSecondStep && selectedNeeds.fulfilled.length === 0 && selectedNeeds.unfulfilled.length === 0)
    ) {
      navigate(routes.feedbackFormPage1(linkCode));
    }
  }, [isLectureLoading, lecture, selectedFeelings, navigate, isGreaterThanSecondStep]);
};
