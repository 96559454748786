import React from 'react';
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { bubbleChartData } from '../utils/fixtures';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const options = {
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        display: false,
        min: -5,
        max: 5,
      },
      grid: {
        lineWidth: (context: any) => (context.tick.value === 0 ? 2 : 0),
        drawBorder: false,
      },
      display: true,
      title: {
        display: true,
        text: '<- NÍZKÁ INTENZITA x VYSOKÁ INTENZITA ->',
      },
    },
    x: {
      ticks: {
        display: false,
        min: -4,
        max: 5,
      },
      beginAtZero: true,
      display: true,
      grid: {
        drawBorder: false,
        lineWidth: (context: any) => (context.tick.value === 0 ? 2 : 0),
      },
      title: {
        display: true,
        text: '<- PŘÍJEMNÉ x NEPŘÍJEMNÉ ->',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context: any) => context.label,
      },
    },
  },
};

// const totalCounts = bubbleChartData.datasets.reduce((acc, d) => acc + d.data.length, 0);

const totalCounts = (maxR: number) =>
  bubbleChartData(maxR).datasets.reduce((acc, d) => {
    const subTotal = d.data.reduce((total, coords) => total + coords.r, 0);

    return acc + subTotal;
  }, 0);

const totalCoords = (maxR: number) =>
  bubbleChartData(maxR).datasets.reduce(
    (acc, d) => {
      const subTotal = d.data.reduce(
        (total, coords) => ({ x: total.x + coords.x * coords.r, y: total.y + coords.y * coords.r }),
        { x: 0, y: 0 }
      );

      return { x: acc.x + subTotal.x, y: acc.y + subTotal.y };
    },
    { x: 0, y: 0 }
  );

const finalData = (maxR: number) => ({
  ...bubbleChartData,
  datasets: bubbleChartData(maxR).datasets.concat({
    label: 'Průměr',
    data: [
      {
        x: totalCoords(maxR).x / totalCounts(maxR),
        y: totalCoords(maxR).y / totalCounts(maxR),
        r: 5,
      },
    ],
    backgroundColor: 'rgba(0, 0, 0, 1)',
  }),
});

interface Props {
  maxR: number;
}

export const BubbleChart = ({ maxR }: Props) => {
  return <Bubble options={options} data={finalData(maxR)} />;
};
