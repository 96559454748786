import { Feeling } from '@/types/feeling';

export const feelingType = (feeling: Feeling): 'pleasant' | 'unpleasant' =>
  feeling.type === 'intensePleasant' || feeling.type === 'notIntensePleasant' ? 'pleasant' : 'unpleasant';

export const feelingEmoji = (moodIntensity: number) => {
  if (moodIntensity < 25) {
    return 'very-sad';
  } else if (moodIntensity < 50) {
    return 'sad';
  } else if (moodIntensity <= 75) {
    return 'happy';
  } else {
    return 'very-happy';
  }
};

export const feelingText = (moodIntensity: number) => {
  if (moodIntensity < 25) {
    return 'hrozný';
  } else if (moodIntensity < 50) {
    return 'nic moc';
  } else if (moodIntensity <= 75) {
    return 'dobrý';
  } else {
    return 'super';
  }
};

export const feelingColor = (moodIntensity: number) => {
  if (moodIntensity < 25) {
    return `rgba(199, 8, 8, ${1 - ((moodIntensity * 4) / 100) * 0.5})`;
  } else if (moodIntensity < 50) {
    return `rgba(110, 193, 253, ${1 - (((moodIntensity - 25) * 4) / 100) * 0.5})`;
  } else if (moodIntensity <= 75) {
    return `rgba(124, 255, 189, ${(((moodIntensity - 50) * 4) / 100) * 0.4 + 0.6})`;
  } else {
    return `rgba(255, 235, 95, ${(((moodIntensity - 75) * 4) / 100) * 0.4 + 0.6})`;
  }
};
