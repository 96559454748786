export const filterUnique = <T>(arr: T[]): T[] => arr.filter((item, i, array) => array.indexOf(item) === i);

export const groupArray = <T, K extends string | number | symbol>(
  array: T[],
  keyCallback: (item: T) => K
): Record<K, T[]> =>
  array.reduce<Record<K, T[]>>((result: Record<K, T[]>, item: T) => {
    const key = keyCallback(item);

    return {
      ...result,
      [key]: !result[key] ? [item] : result[key].concat(item),
    };
  }, {} as Record<K, T[]>);

export const indexArray = <T, K extends string | number | symbol>(
  array: T[],
  keyCallback: (item: T) => K
): Record<K, T> =>
  array.reduce<Record<K, T>>(
    (result: Record<K, T>, item: T) => ({
      ...result,
      [keyCallback(item)]: item,
    }),
    {} as Record<K, T>
  );

export const sumArray = <T>(array: T[], sumCallback: (item: T) => number): number =>
  array.reduce((sum, item) => sum + sumCallback(item), 0);

export const chunkArray = (arr: any, chunkSize: any) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};
