import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { CircularProgress } from '@mui/material';

interface Props {
  value: string;
  isLoading: boolean;
  onChange: (text: string) => void;
  onSubmit: () => void;
}

export const CodeTextField = ({ value, isLoading, onChange, onSubmit }: Props) => {
  return (
    <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        style={{ height: 60 }}
        placeholder="Vstupní kód"
        inputProps={{ 'aria-label': 'Vstupní kód' }}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {isLoading ? (
        <CircularProgress size={32} style={{ marginRight: 10 }} />
      ) : (
        <IconButton color="primary" onClick={onSubmit}>
          <ArrowCircleRightIcon style={{ fontSize: 40 }} />
        </IconButton>
      )}
    </Paper>
  );
};
