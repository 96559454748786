import * as React from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/routes';
import { FeedbackFormHeader } from '@/components/FeedbackFormHeader';
import { FeelingsAccordion } from './components/FeelingsAccordion';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useLectureByLinkCode } from '@/hooks/api/useLectureByLinkCode';
import { FullscreenSpinner } from '@/components/FullscreenSpinner';
import { useCheckCorrectFormStep } from '@/hooks/useCheckCorrectFormStep';

export const FeedbackFormPage2bPage = () => {
  useCheckCorrectFormStep();

  const navigate = useNavigate();

  const linkCode = useLinkCodeParam();

  const { isLoading, error, data } = useLectureByLinkCode(linkCode);

  if (error) {
    return <Typography>Lekce nenalezena</Typography>;
  }

  if (isLoading || !data) {
    return <FullscreenSpinner />;
  }

  const lecture = data.data;

  return (
    <>
      <FeedbackFormHeader lectureName={lecture.name} activeStep={2} />

      <Box mb={2}>
        <Alert severity="info">K jednotlivým pocitům prosím přiřaďte potřeby, které se k nim váží.</Alert>
      </Box>

      <Box mb={3}>
        <FeelingsAccordion />
      </Box>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate(routes.feedbackFormPage2(linkCode));
          }}
        >
          Zpět
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            navigate(routes.feedbackFormPage3(linkCode));
          }}
        >
          Pokračovat
        </Button>
      </div>
    </>
  );
};
