import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButtonBox } from '@/components/IconButtonBox';
import * as React from 'react';
import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { emotionColorsAlternative } from '@/utils/data';
import { filterUnique, indexArray } from '@/services/array';
import { useContext } from 'react';
import { AppContext } from '@/context/AppContext';
import { feelingType } from '@/utils/feeling';
import { FeelingCaption } from '@/components/FeelingCaption';

export const FeelingsAccordion = () => {
  const { selectedFeelings, selectedNeeds, feelingsToNeeds, updateFeelingsToNeeds, toggleFeelingsToNeeds } =
    useFeedbackDataContext();

  const feelingsToNeedsById = indexArray(feelingsToNeeds, (feelingToNeed) => feelingToNeed.feelingId);

  const { appContext } = useContext(AppContext);

  const emotionsById = appContext.feelings.feelingsById;
  const needsById = appContext.needs.needsById;

  return (
    <div>
      {selectedFeelings
        .filter((selectedFeelingId) => {
          const feeling = emotionsById[selectedFeelingId];

          return (
            (feelingType(feeling) === 'pleasant' && selectedNeeds.fulfilled.length > 0) ||
            (feelingType(feeling) === 'unpleasant' && selectedNeeds.unfulfilled.length > 0)
          );
        })
        .map((selectedFeelingId) => {
          const feeling = emotionsById[selectedFeelingId];
          const feelingToNeed = feelingsToNeedsById[selectedFeelingId];
          const correspondingNeeds = selectedNeeds[feelingType(feeling) === 'pleasant' ? 'fulfilled' : 'unfulfilled'];

          return (
            <Accordion
              key={selectedFeelingId}
              expanded={feelingToNeed ? feelingToNeed?.expanded : false}
              onChange={() => {
                toggleFeelingsToNeeds(selectedFeelingId);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${selectedFeelingId}a-content`}
                id={`panel${selectedFeelingId}a-header`}
              >
                <FeelingCaption feeling={feeling} />
              </AccordionSummary>
              <AccordionDetails>
                {correspondingNeeds.map((needId) => {
                  const need = needsById[needId];

                  return (
                    <Box mb={2} key={needId}>
                      <IconButtonBox
                        title={need.name}
                        icon={need.icon}
                        selected={feelingToNeed ? feelingToNeed.needIds.indexOf(need.id) > -1 : false}
                        onClick={() => {
                          updateFeelingsToNeeds(selectedFeelingId, need.id);
                        }}
                      />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};
