import React, { useContext } from 'react';
import { Alert, AppBar, Box, Button, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { emotionColors } from '@/utils/data';
import CheckIcon from '@mui/icons-material/Check';
import { Feeling } from '@/types/feeling';
import { chunkArray } from '@/services/array';
import { AppContext } from '@/context/AppContext';
import { DialogTransition } from '@/components/DialogTransition';

interface Props {
  open: boolean;
  handleClose: () => void;
  type: string | null;
  selectedFeelings: string[];
  toggleSelectedFeeling: (feelingId: string) => void;
}

export const FeelingsDialog = ({ open, handleClose, type, selectedFeelings, toggleSelectedFeeling }: Props) => {
  const { appContext } = useContext(AppContext);

  const emotions = appContext.feelings.feelingsByType;

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={DialogTransition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Pocity
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Hotovo
            </Button>
          </Toolbar>
        </AppBar>

        {/*<Box p={3}>*/}
        {/*  <Box mb={3}>*/}
        {/*    <TextField id="outlined-basic" label="Vyhledejte název pocitu" variant="outlined" fullWidth />*/}
        {/*  </Box>*/}

        {/*  <SelectedEmotions items={selectedEmotions.filter(em => em.type === type)} onDelete={toggleSelectedEmotion} scrollable />*/}

        {/*  {!!type && emotions[type].map((emotion, i) => (*/}
        {/*      <Box key={i} mb={2}>*/}
        {/*        <ButtonBox title={emotion.title} color={`rgba(${emotionColors[type].bg}, ${1 - (1 / emotions[type].length) * i})`} selected={selectedEmotions.map(em => em.id).indexOf(emotion.id) > -1} onClick={() => { toggleSelectedEmotion(emotion) }} />*/}
        {/*      </Box>*/}
        {/*  ))}*/}
        {/*</Box>*/}

        {/*<Box p={2}>*/}

        <Box m={2}>
          <Alert severity="info">Vybírejte jednotlivé pocity z tabulky.</Alert>
        </Box>

        {type !== null && (
          <table style={{ borderSpacing: 0 }} cellSpacing="0" cellPadding="0">
            <tbody>
              {chunkArray(emotions[type], 4).map((chunk, y) => (
                <tr key={y}>
                  {chunk.map((emotion: Feeling, x: number) => {
                    const currentColor = `rgba(${emotionColors[type].bg}, ${emotionColors[type].rgbaAlpha(x, y)})`;
                    const selected = selectedFeelings.indexOf(emotion.id) > -1;

                    return (
                      <td
                        key={x}
                        onClick={() => {
                          toggleSelectedFeeling(emotion.id);
                        }}
                        style={{
                          verticalAlign: 'top',
                          width: '25%',
                          height: 80,
                          // border: `1px solid ${selected ? emotionColors[type].text : 'transparent'}`,
                          background: currentColor,
                          padding: 8,
                          paddingTop: 16,
                          color: '#fff',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: `0.8rem`,
                              color: emotionColors[type].text,
                              textAlign: 'center',
                            }}
                          >
                            {emotion.name}
                          </Typography>

                          {selected && (
                            <Typography style={{ color: emotionColors[type].text }}>
                              <CheckIcon fontSize={'large'} />
                            </Typography>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/*</Box>*/}

        {/*<List>*/}
        {/*  <ListItem button>*/}
        {/*    <ListItemText primary="Phone ringtone" secondary="Titania" />*/}
        {/*  </ListItem>*/}
        {/*  <Divider />*/}
        {/*  <ListItem button>*/}
        {/*    <ListItemText*/}
        {/*      primary="Default notification ringtone"*/}
        {/*      secondary="Tethys"*/}
        {/*    />*/}
        {/*  </ListItem>*/}
        {/*</List>*/}
      </Dialog>
    </div>
  );
};
