import * as React from 'react';
import { ReactNode, useContext } from 'react';
import { emotionColorsAlternative } from '../../../utils/data';
import { Typography } from '@mui/material';
import { FeelingType } from '../../../types/feeling';
import { useFeedbackDataContext } from '../../../hooks/useFeedbackDataContext';
import { AppContext } from '../../../context/AppContext';

interface Props {
  emoji: string;
  type: FeelingType;
  onClick: (type: FeelingType) => void;
}

const feelingTitle: Record<FeelingType, ReactNode> = {
  intenseUnpleasant: (
    <>
      Vysoká intenzita
      <br />
      Nepříjemné
    </>
  ),
  notIntenseUnpleasant: (
    <>
      Nízká intenzita
      <br />
      Nepříjemné
    </>
  ),
  notIntensePleasant: (
    <>
      Nízká intenzita
      <br />
      Příjemné
    </>
  ),
  intensePleasant: (
    <>
      Vysoká intenzita
      <br />
      Příjemné
    </>
  ),
};

const borders: Record<FeelingType, string> = {
  intenseUnpleasant: '16px 0 0 0',
  notIntenseUnpleasant: '0 0 0 16px',
  notIntensePleasant: '0 0 16px 0',
  intensePleasant: '0 16px 0 0',
};

export const FeelingQuadrant = ({ emoji, type, onClick }: Props) => {
  const { selectedFeelings } = useFeedbackDataContext();

  const { appContext } = useContext(AppContext);

  const emotionsById = appContext.feelings.feelingsById;

  return (
    <div
      style={{
        borderRadius: borders[type],
        width: '50%',
        height: 200,
        color: '#000',
        background: `rgb(${emotionColorsAlternative[type].bg})`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
      onClick={() => {
        onClick(type);
      }}
    >
      <Typography>
        {feelingTitle[type]}
        <br />
        <br />
        {/*<MoodBad fontSize={'large'} />*/}
        <img src={`/emojis/${emoji}.png`} width="35" height="35" alt="" />
        <br />
        <br />
        Zvolené: {selectedFeelings.filter((feelingId) => emotionsById[feelingId].type === type).length}
      </Typography>
    </div>
  );
};
