import * as React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { StyledTableCell, StyledTableRow } from '../../components/Table';

export const LecturesListPage = () => {
  const lectures = [
    {
      name: 'Didaktika',
      date: '22.9.2022',
    },
    {
      name: 'Didaktika',
      date: '24.9.2022',
    },
    {
      name: 'Reflexe',
      date: '25.9.2022',
    },
    {
      name: 'Angličtina',
      date: '25.9.2022',
    },
    {
      name: 'Angličtina',
      date: '26.9.2022',
    },
    {
      name: 'Angličtina',
      date: '27.9.2022',
    },
  ];

  return (
    <>
      <div style={{ margin: 'auto', width: 1600 }}>
        <Paper elevation={3}>
          <Box p={3}>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} mb={3}>
              <b>Seznam lekcí</b>
            </Typography>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Seminář</StyledTableCell>
                    <StyledTableCell>Datum</StyledTableCell>
                    <StyledTableCell align="right">Akce</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {lectures.map((lecture, i) => (
                    <StyledTableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {lecture.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {lecture.date}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        <QueryStatsIcon />
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </div>
    </>
  );
};
