import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Slider, Typography } from '@mui/material';
import { useFeedbackDataContext } from '@/hooks/useFeedbackDataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@/routes';
import { FeedbackFormHeader } from '@/components/FeedbackFormHeader';
import { useLinkCodeParam } from '@/hooks/useLinkCodeParam';
import { useLectureByLinkCode } from '@/hooks/api/useLectureByLinkCode';
import { FullscreenSpinner } from '@/components/FullscreenSpinner';
import { feelingColor, feelingEmoji, feelingText } from '@/utils/feeling';
import { MoodBox } from '@/components/MoodBox';

export const FeedbackFormV2Page1Page = () => {
  const [, set] = useState();

  const [type, setType] = useState<string | null>(null);

  const { moodIntensity, updateMoodIntensity } = useFeedbackDataContext();

  const linkCode = useLinkCodeParam();

  const navigate = useNavigate();

  const { isLoading, error, data } = useLectureByLinkCode(linkCode);

  const location = useLocation();

  useEffect(() => {
    // redirect to last page if feedback has been submitted
    // if (
    //   data?.data.isExistingFeedback &&
    //   (location.pathname.indexOf(routes.feedbackFormV2Page1('')) > -1 ||
    //     location.pathname.indexOf(routes.feedbackFormV2Page2('')) > -1)
    // ) {
    //   navigate(routes.feedbackFormV2Page3(linkCode));
    // }
  }, [location, data]);

  const open = type !== null;

  if (error) {
    return <Typography>Lekce nenalezena</Typography>;
  }

  if (isLoading || !data) {
    return <FullscreenSpinner />;
  }

  const lecture = data.data;

  const onNextStepClick = () => {
    navigate(routes.feedbackFormV2Page2(linkCode));
  };

  return (
    <>
      <FeedbackFormHeader lectureName={lecture.name} activeStep={0} hideStepper />

      <Box mb={2}>
        <Typography>Jak se právě teď cítíš?</Typography>
      </Box>

      <MoodBox moodIntensity={moodIntensity} />

      <Box mb={3}>
        <Slider
          value={moodIntensity}
          aria-label="Default"
          valueLabelDisplay="auto"
          onChange={(_: Event, newValue: number) => {
            updateMoodIntensity(newValue);
          }}
        />
      </Box>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={onNextStepClick}>
          Pokračovat
        </Button>
      </div>
    </>
  );
};
