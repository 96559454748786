import * as React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { routes } from '../../routes';

const testLecturelinkcode = 'MbrhHfwp250FsvfBXdOs';

export const HomepagePage = () => (
  <div>
    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3}>
      <b>Student - zadání feedbacku</b>
    </Typography>
    <Link to={routes.introPage}>
      <Typography>Intro page</Typography>
    </Link>{' '}
    <br />
    <Link to={routes.feedbackFormPage1(testLecturelinkcode)}>
      <Typography>Feedback formulář - pozitivní pocity</Typography>
    </Link>{' '}
    <br />
    <Link to={routes.feedbackFormPage2(testLecturelinkcode)}>
      <Typography>Feedback formulář - naplněné potřeby</Typography>
    </Link>
    <br />
    <Link to={routes.feedbackFormPage3(testLecturelinkcode)}>
      <Typography>Feedback formulář - feedback</Typography>
    </Link>
    <br />
    <Link to={routes.feedbackFormPage2b(testLecturelinkcode)}>
      <Typography>Feedback formulář - přiřazení potřeb k pocitům</Typography>
    </Link>
    <br />
    <Link to={routes.feedbackFormPage4(testLecturelinkcode)}>
      <Typography>Feedback formulář - shrnutí</Typography>
    </Link>
    <br />
    <Link to={routes.feedbackStatistics}>
      <Typography>Statistiky</Typography>
    </Link>
    <br />
    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} mb={3} mt={3}>
      <b>Lektor - admin</b>
    </Typography>
    <Link to={'/admin-statistics'}>
      <Typography>Statistiky</Typography>
    </Link>
    <br />
    <Link to={'/admin-statistics-feeling-detail'}>
      <Typography>Statistiky - detail pocitu</Typography>
    </Link>
    <br />
    <Link to={'/admin-statistics-quadrant-detail'}>
      <Typography>Statistiky - detail kvadrantu</Typography>
    </Link>
    <br />
    <Link to={'/admin-lecture-detail'}>
      <Typography>Detail lekce</Typography>
    </Link>
    <br />
    <Link to={'/admin-seminars-list'}>
      <Typography>Seznam seminářů</Typography>
    </Link>
    <br />
    <Link to={'/admin-lectures-list'}>
      <Typography>Seznam lekcí</Typography>
    </Link>
    <br />
  </div>
);
