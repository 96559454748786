import * as React from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { DialogTransition } from '@/components/DialogTransition';

interface Props {
  message: string;
  open: boolean;
  onClose: () => void;
}

export const MessageDialog = ({ message, open, onClose }: Props) => (
  <Dialog open={open} TransitionComponent={DialogTransition}>
    <Box p={3}>
      <Box mb={2}>
        <Typography style={{ textAlign: 'center' }}>{message}</Typography>
      </Box>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={onClose}>
          Zavřít
        </Button>
      </div>
    </Box>
  </Dialog>
);
